import React, { useRef, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ResponsiblesActions from "~/store/ducks/responsible/list";
import FirstAprovalActions from "~/store/ducks/firstAproval/fast";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SelectTest, Textarea, Input } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import { CloseCircle } from "@styled-icons/evaicons-solid/";
import * as S from "./styles";

function ModalFastAproval({ open, handleClose, onFastAprovalSubmit, list }) {
  const [resval, setResval] = useState();
  const [deadLine, setDeadLine] = useState();
  const [checkStatus, setCheckStatus] = useState(false);
  const dispatch = useDispatch();
  const formAction = useRef();

  const { data: responsbile } = useSelector((state) => state.responsibles);

  const close = () => {
    handleClose();
  };

  const fectResponsibles = useCallback(() => {
    dispatch(ResponsiblesActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectResponsibles();
  }, [fectResponsibles]);

  async function handleApprove() {
    if (resval || checkStatus) {
      formAction.current.setErrors({});
      try {
        const data = {
          list: list,
        };

        if (checkStatus) {
          data.status = "FINALIZADO";
        } else {
          data.status = "APROVADO";
          data.responsible_id = resval.id;
          data.corteva_dead_line = moment.utc(deadLine);
          data.name = resval.name;
        }

        await dispatch(FirstAprovalActions.request(data));
        onFastAprovalSubmit();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formAction.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function handleReject(values) {
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        description: Yup.string().required(
          "Observação obrigatória para rejeições!"
        ),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        list: list,
        description: values.description,
        status: "REJEITADO",
      };
      await dispatch(FirstAprovalActions.request(data));
      onFastAprovalSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }
  }

  function handleResp(data) {
    setResval(data);
  }

  function handleDeadLine(data) {
    setDeadLine(data.target.value);
  }

  return (
    <Modal open={open} close={close}>
      <S.Container>
        <S.Title>
          <Text id="aprovacao" dm="Aprovação" />
        </S.Title>
        <S.BtnClose onClick={close}>
          <CloseCircle size="30" />
        </S.BtnClose>
        <Form ref={formAction} onSubmit={handleReject}>
          <S.Row>
            <S.Wrapper>
              <input
                type="checkbox"
                checked={checkStatus}
                value={checkStatus}
                onChange={(e) => {
                  setCheckStatus(!checkStatus);
                }}
              />
              <p>
                <Text id="AprovacaoDireta" dm="Aprovação direta" />
              </p>
            </S.Wrapper>
          </S.Row>
          {!checkStatus && (
            <>
              <S.Row>
                <SelectTest
                  label={<Text id="responsavel" dm="Responsável" />}
                  name="response"
                  placeholder="Selecione"
                  options={responsbile && responsbile.data}
                  onChange={handleResp}
                />
              </S.Row>
              <S.Row>
                <Input
                  label={
                    <Text id="corteva_dead_line" dm="Prazo de aprovação" />
                  }
                  name="corteva_dead_line"
                  type="date"
                  min={moment().utc().format("YYYY-MM-DD")}
                  onChange={handleDeadLine}
                />
              </S.Row>
            </>
          )}
          <S.Row>
            <Textarea
              label={<Text id="observação" dm="Observação" />}
              placeholder="Observação somente para rejeições."
              id="description"
              name="description"
              maxlength={1000}
              cols="30"
              rows="10"
            />
          </S.Row>
          <S.WrapBtns>
            <Button type="submit" btStyle="delete">
              <Text id="reprovar" dm="Reprovar" />
            </Button>
            <Button type="button" btStyle="primary" onClick={handleApprove}>
              <Text id="aprovar" dm="Aprovar" />
            </Button>
          </S.WrapBtns>
        </Form>
      </S.Container>
    </Modal>
  );
}

export default ModalFastAproval;


