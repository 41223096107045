import React, { useRef, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ResponsiblesActions from "~/store/ducks/responsible/list";
import FirstAprovalActions from "~/store/ducks/firstAproval/new";
import JustifyFileActions from "~/store/ducks/justifyFile/new";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SelectTest, Textarea, Input } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import Loading from "~/components/Loading";
import { CloseCircle } from "@styled-icons/evaicons-solid/";
import * as S from "./styles";
import fileImg from "~/assets/icons/file.svg";
import JustifyFileGetLinkActions from "~/store/ducks/justifyGetLink/get";
import * as FileSaver from "file-saver";

function ModalAproval({ open, handleClose, onAprovalSubmit, justify_alert }) {
  const [resval, setResval] = useState();
  const [deadLine, setDeadLine] = useState();
  const [checkStatus, setCheckStatus] = useState(false);
  const dispatch = useDispatch();
  const formAction = useRef();

  const { data: responsbile } = useSelector((state) => state.responsibles);
  const { data: newFile, loading: loadingFiles } = useSelector(
    (state) => state.justifyFile
  );
  const { data: fileGetLink, loading: loadingFileGetLink } = useSelector(
    (state) => state.justificationFileGetLink
  );

  const [files, setFiles] = useState([]);

  const [fileNameKey, setFileNameKey] = useState();

  // const handleFileName = (fileName) => {
  //   const fileSplit = fileName.split("amazonaws.com/");
  //   const fileKey = fileSplit[1];
  //   setFileNameKey(fileKey);
  //   dispatch(JustifyFileGetLinkActions.request(fileKey));
  // };

  const handleFile = (fileName) => {
    const fileSplit = fileName.split("amazonaws.com/");
    const fileKey = fileSplit[1];
    setFileNameKey(fileKey);
    dispatch(JustifyFileGetLinkActions.request(fileKey));
  };

  useEffect(() =>{
    if(fileGetLink && fileNameKey){
      fetch(fileGetLink)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, fileNameKey));
      
      dispatch(JustifyFileGetLinkActions.reset());
      setFileNameKey();
    }
  }, [fileGetLink]);

  const close = () => {
    handleClose();
    dispatch(JustifyFileActions.reset());
    setFiles([]);
  };

  const fectResponsibles = useCallback(() => {
    dispatch(ResponsiblesActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectResponsibles();
  }, [fectResponsibles]);

  useEffect(() => {
    if (newFile) {
      const data = {
        id: newFile.id,
        name: newFile.name,
        path: newFile.path,
      };

      setFiles([...files, data]);
    } else {
      setFiles([]);
    }
  }, [dispatch, newFile]);

  function handleResp(data) {
    setResval(data);
  }

  function handleDeadLine(data) {
    setDeadLine(data.target.value);
  }

  async function handleApprove() {
    if (resval || checkStatus) {
      formAction.current.setErrors({});
      try {
        const data = {
          justify_alert_id: justify_alert.id,
          files,
        };

        if (checkStatus) {
          data.status = "FINALIZADO";
        } else {
          data.status = "APROVADO";
          data.responsible_id = resval.id;
          data.corteva_dead_line = moment.utc(deadLine);
          data.name = resval.name;
        }

        console.log(checkStatus, data);

        await dispatch(FirstAprovalActions.request(data));
        onAprovalSubmit();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formAction.current.setErrors(validationErrors);
        }
      }
    }

    dispatch(JustifyFileActions.reset());
    setFiles([]);
  }

  async function handleReject(values) {
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        description: Yup.string().required(
          "Observação obrigatória para rejeições!"
        ),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        justify_alert_id: justify_alert.id,
        description: values.description,
        status: "REJEITADO",
      };
      await dispatch(FirstAprovalActions.request(data));
      onAprovalSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }

    dispatch(JustifyFileActions.reset());
    setFiles([]);
  }

  async function insertFile(file) {
    const fileData = file[0];

    const data = new FormData();
    data.append("file", fileData);
    dispatch(JustifyFileActions.request(data));
  }

  return (
    <Modal open={open} close={close}>
      {justify_alert && (
        <S.Container>
          <S.Title>
            <Text id="aprovação" dm="Aprovação" />
          </S.Title>
          <S.BtnClose onClick={close}>
            <CloseCircle size="30" />
          </S.BtnClose>
          <Form ref={formAction} onSubmit={handleReject}>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="codigo" dm="Código" />:{" "}
                </strong>
                {justify_alert.id || "-"}
              </p>
              <p>
                <strong>
                  <Text id="descricao" dm="Descrição" />:{" "}
                </strong>
                {justify_alert.justify.name || "-"}
              </p>
            </S.Row>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="user" dm="Usário" />:{" "}
                </strong>
                {(justify_alert.user && justify_alert.user.usuario_nome) || "-"}
              </p>
              <p>
                <strong>
                  <Text id="observacao" dm="Observação" />:{" "}
                </strong>
                {justify_alert.description || "-"}
              </p>
            </S.Row>
            {justify_alert.justify_files && justify_alert.justify_files[0] && (
              <S.Row>
                <S.FileList>
                  <strong>
                    <Text id="files" dm="Arquivos" />:{" "}
                  </strong>
                  {justify_alert.justify_files.map((file) => (
                    <li key={file.path}>
                      {/* <a href={fileGetLink} target="blank"> */}
                        <img src={fileImg} alt="file" onClick={() =>{handleFile(file.path)}}/>
                        {`${file.name.substring(0, 10)}${
                          file.name.length >= 10 ? "..." : ""
                        }`}
                      {/* </a> */}
                    </li>
                  ))}
                </S.FileList>
              </S.Row>
            )}
            <S.Row>
              <S.Wrapper>
                <input
                  type="checkbox"
                  checked={checkStatus}
                  value={checkStatus}
                  onChange={(e) => {
                    setCheckStatus(!checkStatus);
                  }}
                />
                <p>
                  <Text id="AprovacaoDireta" dm="Aprovação direta" />
                </p>
              </S.Wrapper>
            </S.Row>
            {!checkStatus && (
              <>
                <S.Row>
                  <SelectTest
                    label={<Text id="responsavel" dm="Responsável" />}
                    name="response"
                    placeholder="Selecione"
                    options={responsbile && responsbile.data}
                    onChange={handleResp}
                  />
                </S.Row>
                <S.Row>
                  <Input
                    label={
                      <Text id="corteva_dead_line" dm="Prazo de aprovação" />
                    }
                    name="corteva_dead_line"
                    type="date"
                    min={moment().utc().format("YYYY-MM-DD")}
                    onChange={handleDeadLine}
                  />
                </S.Row>
              </>
            )}
            <S.Row>
              <Textarea
                label={<Text id="observação" dm="Observação" />}
                placeholder="Observação somente para rejeições."
                id="description"
                name="description"
                maxlength={1000}
                cols="30"
                rows="10"
              />
            </S.Row>
            <S.Row>
              <Input
                label={<Text id="fileInput" dm="Anexar arquivo" />}
                id="file"
                name="file"
                type="file"
                onChange={(event) =>
                  event.target.files[0] && insertFile(event.target.files)
                }
              />
            </S.Row>
            {loadingFiles ? (
              <Loading />
            ) : (
              <>
                {files && files[0] && (
                  <S.AttachmentList>
                    {files.map((file) => (
                      <li key={file.id}>
                        <img src={fileImg} alt="file" onClick={() =>{handleFile(file.path)}}/>
                        {file.name}
                      </li>
                    ))}
                  </S.AttachmentList>
                )}
              </>
            )}
            <S.WrapBtns>
              <Button type="submit" btStyle="delete">
                <Text id="reprovar" dm="Reprovar" />
              </Button>
              <Button type="button" btStyle="primary" onClick={handleApprove}>
                <Text id="aprovar" dm="Aprovar" />
              </Button>
            </S.WrapBtns>
          </Form>
        </S.Container>
      )}
    </Modal>
  );
}

export default ModalAproval;
