import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductBatchAlertEditActions from "~/store/ducks/productBatchAlert/edit";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Input } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import { CloseCircle } from "@styled-icons/evaicons-solid/";

import * as S from "./styles";

function ModalSap({ open, handleClose, onSapSubmit, productBatch, product_batch_alert_id}) {
  const dispatch = useDispatch();
  const formAction = useRef();

  const { data: user } = useSelector((state) => state.userShow);

  const close = () => {
    handleClose();
  };

  async function handleSubmit(values) {
    formAction.current.setErrors({});
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        sap: Yup.string().required("Sap obrigatório!"),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        sap_code: values.sap,
        user_id: user.usuario_id
      };

      const id = product_batch_alert_id;

      await dispatch(ProductBatchAlertEditActions.request(id, data));
      onSapSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal open={open} close={close}>
      {productBatch && (
        <S.Container>
          <S.Title>
            <Text id="finalizar" dm="Finalizar" />
          </S.Title>
          <S.BtnClose onClick={close}>
            <CloseCircle size="30" />
          </S.BtnClose>
          <Form ref={formAction} onSubmit={handleSubmit}>
            <S.Row templatecolumns="3">
              <p>
                <strong>
                  <Text id="codigo" dm="Código" />: <br />
                </strong>
                {productBatch.product.code}
              </p>
              <p>
                <strong>
                  <Text id="inventory.batch" dm="Lote" />: <br />
                </strong>
                {productBatch.batch}
              </p>
              <p>
                <strong>
                  <Text id="inventory.plant" dm="Planta" />: <br />
                </strong>
                {productBatch.plant.name}
              </p>
            </S.Row>
            <S.Row>
              <Input label="SAP" id="sap" name="sap" />
            </S.Row>
            <S.WrapBtns>
              <Button type="submit" btStyle="primary">
                <Text id="salvar" dm="Salvar" />
              </Button>
            </S.WrapBtns>
          </Form>
        </S.Container>
      )}
    </Modal>
  );
}

export default ModalSap;
