import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import SapImportsActions from "../../ducks/importSap/list";

const getAuthData = (state) => state.auth.data;

export function* fetchSapImports(action) {
  try {
    const { params } = action;
    // const query = params ? queryBuilder(params) : "";
    const url = `inventario-history-sap/status?${params}`;
    const authData = yield select(getAuthData);
    const post = {};
    const { data } = yield call(api.post, url, post, makeOptions(authData));
    yield put(SapImportsActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(SapImportsActions.failure(err.response.data.error.message));
  }
}
