import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ProductBatchDeleteActions from "../../ducks/productBatch/delete";

const getAuthData = (state) => state.auth.data;

export function* productBatchDelete(action) {
  try {
    const { id } = action;
    const url = `product-batch/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));

    yield put(ProductBatchDeleteActions.success(data));
    notify("success", "Seguimento Deletado!");
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(
      ProductBatchDeleteActions.failure(err.response.data.error.message)
    );
  }
}
