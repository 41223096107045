import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  span {
    display: flex;
    gap: 20px;
  }
  svg {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
  small {
    font-size: 15px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.dark};
    font-family: ${({ theme }) => theme.fonts.titleLight};
    border-left: solid 1px;
    padding-left: 10px;
  }
`;

export const Content = styled.div`
  padding: 60px 32px;
`;

export const List = styled.div`
  header {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 4fr 0.5fr;
    padding: 24px 16px;
    p {
      color: ${({ theme }) => theme.colors.lightDarkBlue};
      font-family: ${({ theme }) => theme.fonts.titleBold};
      font-size: 11px;
      text-transform: uppercase;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 4fr 0.5fr;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;
  margin-bottom: 10px;
  :hover {
    transform: scale(1.015);
  }
  p {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
  }
`;

export const ListInit = styled(List)`
  header {
    grid-template-columns: 1fr 4fr 2fr 2fr 0.5fr;
  }
`;

export const ItemInit = styled(Item)`
  grid-template-columns: 1fr 4fr 2fr 2fr 0.5fr;
`;
export const WrapForm = styled.div`
  border-radius: 6px;
  background: #fff;
  padding: 24px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(${(props) => props.templatecolumns}, 1fr);
  margin-bottom: 20px;
  &.tc2-1 {
    grid-template-columns: 2fr 1fr;
  }
`;

export const WrapBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  button {
    margin-left: 20px;
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.dark};
`;

export const WSMWrapperTitle = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

export const DeleteImport = styled.button.attrs({
  type: "button",
})`
  color: ${({ theme }) => theme.colors.danger};
  transition: 300ms ease;

  :hover {
    transform: scale(1.2);
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
