import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import ProductBatchIndicatorsActions from "../../ducks/productBatchAlert/indicators";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* productBatchIndicators(action) {
  try {
    const url = `product-batch-alert/count`;
    const authData = yield select(getAuthData);
    const { data: response } = yield call(api.get, url, makeOptions(authData));
    yield put(ProductBatchIndicatorsActions.success(response.data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ProductBatchIndicatorsActions.failure(err.response.data.error.message)
    );
  }
}
