import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import FirstAprovalFastActions from "../../ducks/firstAproval/fast";

const getAuthData = (state) => state.auth.data;

export function* createFirstAprovalFast(action) {
  try {
    const { post } = action;
    const url = `first-aproval/fast`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));
    yield put(FirstAprovalFastActions.success(data));
    notify("success", "Aprovações cadastrada!");
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(FirstAprovalFastActions.failure(err.response.data.error.message));
  }
}
