import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SecondAprovalEditActions from "~/store/ducks/secondAproval/edit";
import JustifyFileActions from "~/store/ducks/justifyFile/new";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Textarea, Input } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import Loading from "~/components/Loading";
import { CloseCircle } from "@styled-icons/evaicons-solid/";
import * as S from "./styles";
import fileImg from "~/assets/icons/file.svg";

function ModalSecondAproval({ open, handleClose, onAprovalSubmit, second_approval, justify_alert }) {
  const dispatch = useDispatch();
  const formAction = useRef();

  const { data: newFile, loading: loadingFiles } = useSelector((state) => state.justifyFile);
  const [status, setStatus] = useState(null);
  const [files, setFiles] = useState([]);

  const close = () => {
    handleClose();
    dispatch(JustifyFileActions.reset());
    setFiles([]);
  };

  useEffect(() => {
    if(newFile) {
      const data = {
        id: newFile.id,
        name: newFile.name,
        path: newFile.path,
      };

      setFiles([...files, data]);
    } else {
      setFiles([]);
    }
  }, [dispatch, newFile]);

  async function handleSubmit(values) {
    formAction.current.setErrors({});

    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        description: Yup.string().required(
          "Observação obrigatória!"
        ),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        status,
        description: values.description,
        files,
      };

      const id = second_approval.id;

      await dispatch(SecondAprovalEditActions.request(id, data));
      onAprovalSubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }

    dispatch(JustifyFileActions.reset());
    setFiles([]);
  }

  async function insertFile(file) {
    const fileData = file[0];

    const data = new FormData();
    data.append('file', fileData);
    dispatch(JustifyFileActions.request(data));
  }

  return (
    <Modal open={open} close={close}>
      {second_approval && justify_alert && (
        <S.Container>
          <S.Title>
            <Text id="aprovação" dm="Aprovação" />
          </S.Title>
          <S.BtnClose onClick={close}>
            <CloseCircle size="30" />
          </S.BtnClose>
          <Form ref={formAction} onSubmit={handleSubmit}>
            <S.SubTitle>
              <Text id="justificativa" dm="Justificativa" />
            </S.SubTitle>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="codigo" dm="Código" />:{" "}
                </strong>
                {justify_alert.id || "-"}
              </p>
              <p>
                <strong>
                  <Text id="descricao" dm="Descrição" />:{" "}
                </strong>
                {justify_alert.justify.name || "-"}
              </p>
            </S.Row>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="user" dm="Usário" />:{" "}
                </strong>
                {justify_alert.user && justify_alert.user.usuario_nome || "-"}
              </p>
              <p>
                <strong>
                  <Text id="observacao" dm="Observação" />:{" "}
                </strong>
                {justify_alert.description || "-"}
              </p>
            </S.Row>
            {
              justify_alert.justify_files &&
              justify_alert.justify_files[0] && (
                <S.Row>
                  <S.FileList>
                    <strong>
                      <Text id="files" dm="Arquivos" />:{' '}
                    </strong>
                    {
                      justify_alert.justify_files.map(file => (
                        <li key={file.path}>
                          <a
                            href={file.path}
                            target="blank"
                          >
                            <img src={fileImg} alt="file"/>
                            {`${file.name.substring(0, 10)}${
                              file.name.length >= 10 ? '...' : ''
                            }`}
                          </a>
                        </li>
                      ))
                    }
                  </S.FileList>
                </S.Row>
              )
            }
            <S.SubTitle>
              <Text id="4plApproval" dm="4pl Approval" />
            </S.SubTitle>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="codigo" dm="Código" />: <br />
                </strong>
                {justify_alert.first_aproval.id || "-"}
              </p>
              <p>
                <strong>
                  <Text id="user" dm="Usário" />:{" "}
                </strong>
                {justify_alert.first_aproval.user && justify_alert.first_aproval.user.usuario_nome || "-"}
              </p>
            </S.Row>
            <S.Row>
              <p>
                <strong>
                  <Text id="descricao" dm="Descrição" />: <br />
                </strong>
                {justify_alert.first_aproval.description || "-"}
              </p>
            </S.Row>
            <S.SubTitle>
              <Text id="cortevaApproval" dm="corteva Approval" />
            </S.SubTitle>
            <S.Row templatecolumns="2">
              <p>
                <strong>
                  <Text id="responsavel" dm="Responsavel" />: <br />
                </strong>
                {second_approval.corteva_responsible.name || "-"}
              </p>
              <p>
                <strong>
                  <Text id="prazo" dm="Prazo" />: <br />
                </strong>
                {
                  second_approval.corteva_dead_line ?
                  moment.utc(second_approval.corteva_dead_line).format('DD/MM/YYYY') :
                  "-"
                }
              </p>
            </S.Row>
            {
              justify_alert.first_aproval.justify_files &&
              justify_alert.first_aproval.justify_files[0] && (
                <S.Row>
                  <S.FileList>
                    <strong>
                      <Text id="files" dm="Arquivos" />:{' '}
                    </strong>
                    {
                      justify_alert.first_aproval.justify_files.map(file => (
                        <li key={file.path}>
                          <a
                            href={file.path}
                            target="blank"
                          >
                            <img src={fileImg} alt="file"/>
                            {`${file.name.substring(0, 10)}${
                              file.name.length >= 10 ? '...' : ''
                            }`}
                          </a>
                        </li>
                      ))
                    }
                  </S.FileList>
                </S.Row>
              )
            }
            <S.Row>
              <Textarea
                label={<Text id="observação" dm="Observação" />}
                id="description"
                name="description"
                maxlength={1000}
                cols="30"
                rows="10"
              />
            </S.Row>
            {
            /*
            <S.Row>
              <Input
                label={<Text id="fileInput" dm="Anexar arquivo" />}
                id="file"
                name="file"
                type="file"
                onChange={(event) =>
                  event.target.files[0] && insertFile(event.target.files)
                }
              />
            </S.Row>
            */
            }
            {
            /*loadingFiles ? (
              <Loading />
            ) : (
              <>
                {files && files[0] && (
                  <S.AttachmentList>
                    {files.map(file => (
                      <li key={file.id}>
                        <img src={fileImg} alt="file"/>
                        {file.name}
                      </li>
                    ))}
                  </S.AttachmentList>
                )}
              </>
            )*/
            }
            <S.WrapBtns>
              <Button
                type="submit"
                btStyle="delete"
                onClick={() => setStatus('REJEITADO')}
              >
                <Text id="reprovar" dm="Reprovar" />
              </Button>
              <Button
                type="submit"
                btStyle="primary"
                onClick={() => setStatus('APROVADO')}
              >
                <Text id="aprovar" dm="Aprovar"/>
              </Button>
            </S.WrapBtns>
          </Form>
        </S.Container>
      )}
    </Modal>
  );
}

export default ModalSecondAproval;
