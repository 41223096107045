import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import JustificationsActions from "~/store/ducks/justification/list";
import FilterActions from "~/store/ducks/filter";
import { Container, WrapFilter, Row, WrapBtns, Wrapper } from "./styles";
import { Form } from "@unform/web";
import { Input, SelectTest } from "~/components/Form";
import { Button } from "~/styles/components";
import Text from "~/components/Text";

const months = [
  {
    id: "",
    name: "Todos",
  },
  {
    id: "01",
    name: "Janeiro",
  },
  {
    id: "02",
    name: "Fevereiro",
  },
  {
    id: "03",
    name: "Março",
  },
  {
    id: "04",
    name: "Abril",
  },
  {
    id: "05",
    name: "Maio",
  },
  {
    id: "06",
    name: "Junho",
  },
  {
    id: "07",
    name: "Julho",
  },
  {
    id: "08",
    name: "Agosto",
  },
  {
    id: "09",
    name: "Setembro",
  },
  {
    id: "10",
    name: "Outubro",
  },
  {
    id: "11",
    name: "Novembro",
  },
  {
    id: "12",
    name: "Dezembro",
  },
];

const status = [
  {
    id: "",
    name: "Todos",
  },
  {
    id: "pendenteJustificativa",
    name: "Pendente De Justificativa",
  },
  {
    id: "pendenteAprovacao4PL",
    name: "Pendente Aprovação 4PL",
  },
  {
    id: "pendenteAprovacaoCorteva",
    name: "Pendente Aprovação Corteva",
  },
  {
    id: "pendenteFinalizacao",
    name: "Pendente Finalização",
  },
];

function Filter({ active, onClose, onFilter }) {
  const formLogin = useRef();
  const dispatch = useDispatch();

  const { data: filters } = useSelector((state) => state.filter);
  const { data: justifies } = useSelector((state) => state.justifications);
  justifies &&
    justifies.data &&
    !justifies.data.find((item) => item.name === "Todos") &&
    justifies.data.unshift({ id: "", name: "Todos" });
  const { data: groups } = useSelector((state) => state.inventarioGroupUsers);

  const [isArmazem, setIsArmazem] = useState(false);
  const [showFinished, setShowFinished] = useState(true);

  function closeFilter() {
    onClose(!active);
  }

  const queryStr = window.location.search;
  const searchUrl = new URLSearchParams(queryStr);

  const statusId = searchUrl.get("status");

  function handleReset() {
    dispatch(FilterActions.reset());
    const monthSelect = formLogin.current.getFieldRef("month");
    const justifySelect = formLogin.current.getFieldRef("justify");
    const statusSelect = formLogin.current.getFieldRef("status");
    monthSelect.select.clearValue();
    justifySelect.select.clearValue();
    statusSelect.select.clearValue();
    setShowFinished(true);
    window.scroll({
      top: 0,
    });
    closeFilter();
  }

  async function handleSubmit(data) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        year: Yup.string().required("Este campo é obrigatório!"),
        month: Yup.object().typeError("Este campo é obrigatório!"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let filtersForEnpoint = {};
      let index = 0;
      if (data.id && data.id !== "") {
        filtersForEnpoint.id = data.id;
        index++;
      }
      if (data.legacy && data.legacy !== "") {
        filtersForEnpoint.product_legacy = data.legacy;
        index++;
      }
      if (data.code && data.code !== "") {
        filtersForEnpoint.product_code = data.code;
        index++;
      }
      if (data.batch && data.batch !== "") {
        filtersForEnpoint.batch = data.batch;
        index++;
      }
      if (data.description && data.description !== "") {
        filtersForEnpoint.product_description = data.description;
        index++;
      }
      if (data.plant && data.plant !== "") {
        filtersForEnpoint.plant_description = data.plant;
        index++;
      }
      if (data.user && data.user !== "") {
        filtersForEnpoint.user_name = data.user;
        index++;
      }
      if (data.justify && data.justify.id !== "") {
        filtersForEnpoint.justify_id = data.justify.id;
        index++;
      }
      if (data.sap_code && data.sap_code !== "") {
        filtersForEnpoint.sap_code = data.sap_code;
        index++;
      }
      if (
        (data.month && data.month !== "") ||
        (data.year && data.year !== "")
      ) {
        const date = `${data.year}-${data.month.id || ""}`;
        filtersForEnpoint.date = date;
        index++;
      }
      if (data.status && data.status !== "") {
        filtersForEnpoint.status = data.status.id;
        index++;
      }

      filtersForEnpoint.finished = `${showFinished}`;
      index++;

      dispatch(
        FilterActions.request({
          initialData: {
            ...data,
            finished: showFinished,
          },
          endPoint: filtersForEnpoint,
        })
      );
      onFilter(filtersForEnpoint);
      window.scroll({
        top: 0,
      });
      closeFilter();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  useEffect(() => {
    dispatch(JustificationsActions.request());
  }, [dispatch]);

  useEffect(() => {
    if (filters && filters.initialData) {
      setShowFinished(filters.initialData.finished);
    }
  }, [filters]);

  useEffect(() => {
    if (groups && groups.data[0]) {
      const FilterArmazem = groups.data.find((group) => group === "Armazém");
      const Filter4PL = groups.data.find((group) => group === "4PL");
      const FilterCorteva = groups.data.find((group) => group === "Corteva");

      if (FilterArmazem && !Filter4PL && !FilterCorteva) {
        setIsArmazem(true);
      }
    }
  }, [groups]);

  return (
    <Container className={active ? "ativo" : ""}>
      <WrapFilter className={active ? "ativo" : ""}>
        <header>
          <p>
            <Text id="filtrar" dm="Filtrar" />
          </p>
          <CloseOutline size={20} onClick={closeFilter} />
        </header>
        <Form
          ref={formLogin}
          onSubmit={handleSubmit}
          initialData={filters && filters.initialData}>
          <Row templatecolumns="2">
            <Input label="Id" name="id" placeholder="" />
            <Input
              label={<Text id="inventory.year" dm="Year" />}
              name="year"
              defaultValue="2021"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="2">
            <SelectTest
              label={<Text id="inventory.month" dm="Month" />}
              name="month"
              options={months}
            />
            <Input
              label={<Text id="codigo" dm="Código" />}
              name="code"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="empresa" dm="Empresa" />}
              name="legacy"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="1">
            <Input
              label={<Text id="descricao" dm="Descrição" />}
              name="description"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="2">
            <Input
              label={<Text id="inventory.batch" dm="Lote" />}
              name="batch"
              placeholder=""
            />
            <Input
              label={<Text id="inventory.plant" dm="Planta" />}
              name="plant"
              placeholder=""
            />
          </Row>
          <Row templatecolumns="2">
            <Input
              label={<Text id="user" dm="User" />}
              name="user"
              placeholder=""
            />
            <Input
              label={<Text id="sapCode" dm="SAP Code" />}
              name="sap_code"
              placeholder=""
            />
          </Row>
          <Row templatecolumns={!isArmazem ? 2 : 1}>
            <SelectTest
              label={<Text id="justificativa" dm="Justificativa" />}
              name="justify"
              options={justifies && justifies.data}
            />
            {!isArmazem && (
              <>
                <SelectTest
                  label={<Text id="status" dm="Status" />}
                  name="status"
                  options={status}
                  defaultValue={statusId}
                />
                <Wrapper>
                  <input
                    type="checkbox"
                    name="finished"
                    checked={showFinished}
                    value={showFinished}
                    onChange={(e) => {
                      setShowFinished(!showFinished);
                    }}
                  />
                  <p>
                    <Text id="showFinished" dm="Mostrar itens finalizados" />
                  </p>
                </Wrapper>
              </>
            )}
          </Row>

          <WrapBtns>
            <Button type="reset" btStyle="secondary" onClick={closeFilter}>
              <Text id="cancelar" dm="Cancelar" />
            </Button>
            <Button type="reset" btStyle="secondary" onClick={handleReset}>
              <Text id="clean" dm="Limpar" />
            </Button>
            <Button type="submit" btStyle="primary">
              <Text id="filtrar" dm="Filtrar" />
            </Button>
          </WrapBtns>
        </Form>
      </WrapFilter>
    </Container>
  );
}

export default Filter;
