import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    request: ["post"],
    success: ["data"],
    failure: ["error"],
    delete: ["route"],
    deleted: ["error"],
    reset: [],
  },
  { prefix: "SAP_IMPORT_" }
);

export const SapImportTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

/* Reducers */

export const _request = (state) =>
  update(state, { loading: { $set: true }, error: { $set: null } });

export const _success = (state, action) => {
  return update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });
};

export const _failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _delete = (state) =>
  update(state, { loading: { $set: true }, error: { $set: null } });

export const _deleted = (state) => {
  update(state, { loading: { $set: false }, error: { $set: null } });
}

export const _reset = () => INITIAL_STATE;

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: _request,
  [Types.SUCCESS]: _success,
  [Types.FAILURE]: _failure,
  [Types.RESET]: _reset,
  [Types.DELETE]: _delete,
  [Types.DELETED]: _deleted,
});
