import React, { useEffect, useState } from "react";
import { Route, NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, SubMenu } from "./styles";
import { useFormatMessage } from "react-intl-hooks";

function Menu() {
  const history = useHistory();
  const text = useFormatMessage();
  const { data: groups } = useSelector((state) => state.inventarioGroupUsers);

  const [fourPlStatus, setFourPlStatus] = useState(false);

  useEffect(() => {
    if (groups && groups.data[0]) {
      groups.data.forEach((item) => {
        const name = item.inventario_group && item.inventario_group.name;
        if (name && name === "4PL") {
          setFourPlStatus(true);
        }
      });
    }
  }, [groups]);

  function actual() {
    switch (history.location.pathname) {
      case "/products":
        return "active";
      case "/config/responsible":
        return "active";
      case "/config/segment":
        return "active";
      case "/config/classification":
        return "active";
      case "/config/import":
        return "active";
      case "/config/group":
        return "active";
      default:
        break;
    }
  }
  return (
    <>
      <Container>
        <Route>
          <NavLink to="/dashboard">
            {text({ id: "menu.dashboard", defaultMessage: "Dashboard" })}
          </NavLink>
          <NavLink to="/inventory">
            {text({ id: "inventory.title", defaultMessage: "Inventory" })}
          </NavLink>
          {fourPlStatus && (
            <span className={actual() ? "submenu active" : "submenu"}>
              {text({ id: "menu.config", defaultMessage: "Configurações" })}
              <SubMenu>
                
                  <NavLink to="/config/import">
                    {text({
                      id: "menu.import",
                      defaultMessage: "Importa"
                    })}
                  </NavLink>
              
                <NavLink to="/config/justification">
                  {text({
                    id: "justificativa",
                    defaultMessage: "Justificativa",
                  })}
                </NavLink>
                <NavLink to="/config/responsible">
                  {text({
                    id: "responsavel",
                    defaultMessage: "Responsável",
                  })}
                </NavLink>
                <NavLink to="/config/group">
                  {text({ id: "menu.group", defaultMessage: "Grupo" })}
                </NavLink>
              </SubMenu>
            </span>
           )}
        </Route>
      </Container>
    </>
  );
}

export default Menu;
