import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions, queryBuilder } from "~/services/api";
import ProductBatchAlertsActions from "../../ducks/productBatchAlert/list";

const getAuthData = (state) => state.auth.data;

export function* fetchProductBatchAlerts(action) {
  try {
    const { params } = action;

    const queryStr = window.location.search;
    const search = new URLSearchParams(queryStr);

    const statusId = search.get("status");

    let newParams = params;

    if (statusId && !search.status) {
      newParams = {
        ...newParams,
        status: statusId,
      };
    }

    const query = params ? queryBuilder(newParams) : "";
    const url = `product-batch-alert?${query}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));
    yield put(ProductBatchAlertsActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(
      ProductBatchAlertsActions.failure(err.response.data.error.message)
    );
  }
}
