import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  svg {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 20px;
`;

export const Content = styled.section`
  width: 100%;
  padding: 56px 32px;
`;

export const ProductHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 2px ${({ theme }) => theme.colors.lightDarkBlue} dotted;
  margin-bottom: 32px;
`;

export const ProductName = styled.h1`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  color: ${({ theme }) => theme.colors.blueDark};
  display: flex;
  align-items: center;
`;

export const Dcode = styled.span`
  font-size: 26px;
  font-family: ${({ theme }) => theme.fonts.titleExtraBold};
  margin-right: 16px;
`;

export const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

export const Tag = styled.div`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 12px;
  transition: 300ms ease;
  background-color: ${({ theme }) => theme.colors.darkRgba8};
  color: ${({ theme }) => theme.colors.dark};
  margin-right: 8px;
  text-transform: uppercase;

  :last-child {
    margin-right: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.lightDarkBlue};
    margin-right: 10px;
  }
`;

export const WrapperBoxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapMonth = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 40px;
`;
export const BoxMonth = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.09);
`;

export const TitMonth = styled.div`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.blueDark};
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const BarMonth = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.12);
  position: relative;
  margin-bottom: 15px;
  &:after {
    content: "";
    position: absolute;
    width: ${(props) => props.percent}%;
    bottom: 0;
    height: ${(props) => props.height}px;
    border-radius: 20px;
    background: ${(props) =>
      props.color === "previous" ? "#3B63BC" : "#3BBCA3"};
  }
`;

export const ValueMonth = styled.div`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${(props) => (props.color === "previous" ? "#3B63BC" : "#3BBCA3")};
  font-size: 16px;
`;

export const WrapPrevious = styled.div`
  margin-bottom: 40px;
`;
export const NewPlanner = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  big {
    font-family: ${({ theme }) => theme.fonts.titleBold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blueDark};
    margin-bottom: 20px;
  }
  textarea {
    padding: 20px;
    border: solid 1px #7d93a5;
    border-radius: 5px;
    height: 150px;
    box-sizing: border-box;
    resize: none;
  }
`;

export const WrapBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  button {
    margin-left: 20px;
  }
`;

export const BoxChart = styled.div`
  height: 200px;
  background: #efeff4;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(${(props) => props.templatecolumns}, 1fr);
  margin-bottom: 20px;
  input {
    border-color: #7d93a5;
  }
`;

export const List = styled.div`
  header {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 4fr 2fr 2fr 2fr;
    padding: 24px 16px;
    p {
      color: ${({ theme }) => theme.colors.lightDarkBlue};
      font-family: ${({ theme }) => theme.fonts.titleBold};
      font-size: 11px;
      text-transform: uppercase;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;
  margin-bottom: 10px;
  :hover {
    transform: scale(1.015);
  }
  p {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
  }
`;

