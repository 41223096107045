import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "../pages/Login";
import Inventory from "../pages/Inventory";
import InventoryDetails from "../pages/Inventory/Details";

//Configuração

import Justification from "../pages/Config/Justification";
import JustificationNew from "../pages/Config/Justification/new";
import JustificationDetails from "../pages/Config/Justification/details";

import Responsible from "../pages/Config/Responsible";
import ResponsibleNew from "../pages/Config/Responsible/new";
import ResponsibleDetails from "../pages/Config/Responsible/details";

import Group from "../pages/Config/Group";
import GroupNew from "../pages/Config/Group/new";
import GroupDetails from "../pages/Config/Group/details";
import PlantAssociation from "../pages/Config/PlantAssociation";

import Import from "../pages/Config/Import";

import Dashboard from "../pages/Dashboard";

export default function Routes({ editlanguage }) {
  const { logado } = useSelector((state) => state.auth);
  return (
    <BrowserRouter langtest={editlanguage}>
      <Switch>
        <Route
          path="/"
          exact
          render={() =>
            logado ? (
              <Redirect to={{ pathname: "/inventory" }} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
        <Route
          path="/Login"
          exact
          render={() => logado && <Redirect to={{ pathname: "/inventory" }} />}
          component={!logado && Login}
        />
        <PrivateRoute
          authed={logado}
          path="/inventory"
          exact
          component={Inventory}
        />
        <PrivateRoute
          authed={logado}
          path="/inventory/:id"
          exact
          component={InventoryDetails}
        />

        <PrivateRoute
          authed={logado}
          path="/config/justification"
          exact
          component={Justification}
        />
        <PrivateRoute
          authed={logado}
          path="/config/justification/new"
          exact
          component={JustificationNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/justification/:id"
          component={JustificationDetails}
        />

        <PrivateRoute
          authed={logado}
          path="/config/responsible"
          exact
          component={Responsible}
        />
        <PrivateRoute
          authed={logado}
          path="/config/responsible/new"
          exact
          component={ResponsibleNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/responsible/:id"
          component={ResponsibleDetails}
        />

        <PrivateRoute
          authed={logado}
          path="/config/import"
          exact
          component={Import}
        />

        <PrivateRoute
          authed={logado}
          path="/config/group"
          exact
          component={Group}
        />
        <PrivateRoute
          authed={logado}
          path="/config/group/new"
          exact
          component={GroupNew}
        />
        <PrivateRoute
          authed={logado}
          path="/config/group/:id"
          component={GroupDetails}
        />

        <PrivateRoute
          authed={logado}
          path="/config/plant-association/:id"
          component={PlantAssociation}
        />


        <PrivateRoute
          authed={logado}
          path="/dashboard"
          exact
          component={Dashboard}
        />
      </Switch>
    </BrowserRouter>
  );
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}
