import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: "Gilroy-Bold";
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 8px;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px #dedede solid;
    padding: 16px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.black};
  }

  span {
    font-size: 14px;
    font-family: "Gilroy-SemiBold";
    font-weight: bold;
    color: ${({ theme }) => theme.colors.danger};
    margin: 4px 0;
  }
`;
