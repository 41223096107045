import styled from "styled-components";

export const UserList = styled.ul`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #ddd;

  header {
    strong {
      color: ${({ theme }) => theme.colors.lightDarkBlue};
      font-family: ${({ theme }) => theme.fonts.titleBold};
      font-size: 14px;
      text-transform: uppercase;
    }
  }
`;

export const UserItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;
  margin-bottom: 10px;

  :hover {
    transform: scale(1.015);
  }

  p {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
