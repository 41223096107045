import React, { useRef } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Form } from "@unform/web";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import InventoryHistoryDeleteActions from "~/store/ducks/inventoryHistory/delete";
import { CloseCircle } from "@styled-icons/evaicons-solid/";

import { SelectTest } from "~/components/Form";

import * as S from "./styles";

const MONTH_NAMES = [
  {
    id: "01",
    name: "Janeiro",
  },
  {
    id: "02",
    name: "Fevereiro",
  },
  {
    id: "03",
    name: "Março",
  },
  {
    id: "04",
    name: "Abril",
  },
  {
    id: "05",
    name: "Maio",
  },
  {
    id: "06",
    name: "Junho",
  },
  {
    id: "07",
    name: "Julho",
  },
  {
    id: "08",
    name: "Agosto",
  },
  {
    id: "09",
    name: "Setembro",
  },
  {
    id: "10",
    name: "Outubro",
  },
  {
    id: "11",
    name: "Novembro",
  },
  {
    id: "12",
    name: "Dezembro",
  },
];

function ModalDelete({ open, handleClose, handleDelete }) {
  const formDelete = useRef();

  const dispatch = useDispatch();

  const onClickDelete = () => {
    const monthSelect = formDelete.current.getFieldValue("month");

    if (monthSelect) {
      const { id } = monthSelect;
      const year = new Date().getFullYear();
      const dateString = `${year}-${id}-01`;
      const date = moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD");

      dispatch(InventoryHistoryDeleteActions.request(date));

      handleDelete();
    }
  };

  return (
    <Modal open={open} close={handleClose}>
      <S.Container>
        <S.Title>
          <Text
            id="deletarTodosDoMes"
            dm="Deseja deletar os registros deste mês?"
          />
        </S.Title>
        <S.BtnClose onClick={handleClose}>
          <CloseCircle size="30" />
        </S.BtnClose>
        <Form onSubmit={() => {}} ref={formDelete}>
          <SelectTest
            label={<Text id="inventory.month" dm="Month" />}
            name="month"
            options={MONTH_NAMES}
          />

          <S.WrapBtns>
            <Button type="button" btStyle="primary" onClick={handleClose}>
              <Text id="cancelar" dm="Cancelar" />
            </Button>
            <Button type="submit" btStyle="delete" onClick={onClickDelete}>
              <Text id="deletar" dm="Reprovar" />
            </Button>
          </S.WrapBtns>
        </Form>
      </S.Container>
    </Modal>
  );
}

export default ModalDelete;
