import React from "react";

import Header from "~/components/Header";
import { Container, ContentHeader, Title } from "./styles";

function Dashboard() {
  return (
    <>
      <Header />
      <ContentHeader>
        <Title>Dashboard</Title>
      </ContentHeader>
      <Container></Container>
    </>
  );
}

export default Dashboard;
