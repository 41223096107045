import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    request: ["data", "history"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "FILTER_" }
);

export const FilterTypes = Types;
export default Creators;

/* Initial State */

const getInitialData = () => {
  const query = window.location.search;
  const search = new URLSearchParams(query);

  const statusId = search.get("status");

  if (!statusId) {
    return null;
  }

  return {
    initialData: {
      id: "",
      year: "2022",
      month: { id: "", name: "Todos" },
      code: "",
      legacy: "",
      description: "",
      batch: "",
      plant: "",
      user: "",
      sap_code: "",
      justify: null,
      status: {
        id: statusId,
        name: "Status",
      },
      finished: true,
    },
    endPoint: {
      date: "2022-",
      status: statusId,
      finished: "true",
    },
  };
};

export const INITIAL_STATE = {
  data: getInitialData(),
  loading: false,
  error: null,
};

/* Reducers */

export const _request = (state) =>
  update(state, { loading: { $set: true }, error: { $set: null } });

export const _success = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

export const _failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => {
  setTimeout(() => {
    window.location.search = "";
  }, 2000);
  return { ...INITIAL_STATE, data: null };
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: _request,
  [Types.SUCCESS]: _success,
  [Types.FAILURE]: _failure,
  [Types.RESET]: _reset,
});
