import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0;
`;

export const Button = styled.button.attrs({
  type: "button",
})`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.light};
  border: 1px transparent solid;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.disable};
  margin-right: 4px;
  font-size: 14px;
  border-radius: 6px;
  transition: 300ms ease;
  &.firstlast {
    background-color: ${({ theme }) => theme.colors.darkRgba8};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  &:last-child {
    margin-right: 0;
  }
`;
