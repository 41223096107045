import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PackageIcon, LeftArrowAlt } from "@styled-icons/boxicons-regular";
import ProductBatcheAlertShowActions from "~/store/ducks/productBatchAlert/show";

import { useParams } from "react-router";

import {
  Container,
  Filters,
  Title,
  Content,
  ProductHeader,
  ProductName,
  Dcode,
  Tags,
  Tag,
  SubTitle,
  WrapPrevious,
  List,
  Item,
} from "./styles";


import { ModalAlert } from "~/components/Pages/Inventory/";
import { ButtonMini } from "~/styles/components";
import Header from "~/components/Header";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

function Details() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const { data, loading } = useSelector(
    (state) => state.productBatchAlertShow
  )

  const [item, setItem] = useState(null);
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);


  const fectSegments = useCallback(() => {
    dispatch(ProductBatcheAlertShowActions.request(id));
  }, [dispatch]);

  useEffect(() => {
    fectSegments();
  }, [id]);

  useEffect(() => {
    if(data) {
      setItem(data);
      const justifies = new Array();

      if(data.justify_alerts && data.justify_alerts[0]){
        data.justify_alerts.forEach(justify => {
          justifies.push({
            ...justify,
            sap_code: data.sap_code,
            user_sap: data.user
          });
        });
      }

      setList(justifies);
    }
  }, [data]);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="inventory.title" dm="Inventory" />
        </Title>
        <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
          <LeftArrowAlt size={16} />
          <Text id="voltar" dm="Voltar" />
        </ButtonMini>
      </Filters>

      <ModalAlert
        open={openModal}
        data={modalData}
        handleClose={() => {
          setOpenModal(false);
        }}
      />

      <Content>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ProductHeader>
              <ProductName>
                <Dcode>{item && item.product_batch.product.code}</Dcode>
                {item && (item.product_batch.product.name || item.product.product_batch.description)}
              </ProductName>
            </ProductHeader>
            <Tags>
              <Tag>
                <span>
                  <Text id="inventory.batch" dm="Lote" />:
                </span>
                {item && item.product_batch.batch}
              </Tag>
              <Tag>
                <span>
                  <Text id="inventory.plant" dm="Planta" />:
                </span>
                {item && item.product_batch.plant.name}
              </Tag>
            </Tags>
            <WrapPrevious>
              <SubTitle>
                <Text id="inventory.lastJustifies" dm="Justificativas Anteriores" />
              </SubTitle>
              <List>
                <header>
                  <p>
                    <Text id="justificativa" dm="Justificativa" />
                  </p>
                  <p>
                    <Text id="observacao" dm="Observação" />
                  </p>
                  <p>
                    <Text id="4plApproval" dm="Aprovação 4PL" />
                  </p>
                  <p>
                    <Text id="cortevaApproval" dm="Aprovação Corteva" />
                  </p>
                  <p>
                    <Text id="4plCompletion" dm="Finalização" />
                  </p>
                </header>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {list && list[0] ? list.map((justify) => (
                      <Item
                        key={justify.id}
                        onClick={() => {
                          setModalData({ justify, productBatch: item.product_batch});
                          setOpenModal(true);
                        }}
                      >
                        <p>{justify ? justify.justify.name : ''}</p>
                        <p>{
                          justify && justify.description ?
                          (`
                            ${justify.description.substring(0, 32)}
                            ${justify.description.length >= 32 ? "..." : ""}
                          `) :
                          ''
                        }</p>
                        <p>{
                          justify.first_aproval &&
                          justify.first_aproval.status ||
                          'PENDENTE'
                        }</p>
                        <p>{
                          justify.first_aproval &&
                          justify.first_aproval.status !== 'APROVADO' &&
                          '-' ||
                          justify.first_aproval &&
                          justify.first_aproval.second_aproval &&
                          justify.first_aproval.second_aproval.status ||
                          'PENDENTE'
                        }</p>
                        <p>{
                          justify.first_aproval &&
                          justify.first_aproval.status !== 'APROVADO' &&
                          '-' ||
                          justify.first_aproval &&
                          justify.first_aproval.second_aproval &&
                          justify.first_aproval.second_aproval.status !== 'APROVADO' &&
                          '-' ||
                          justify.sap_code &&'CONCLUÍDA' ||
                          'PENDENTE'
                        }</p>
                      </Item>
                    )) :
                    (
                      <p>Lista Vazia</p>
                    )}
                  </>
                )}
              </List>
            </WrapPrevious>
          </>
        )}
      </Content>
    </Container>
  );
}

export default Details;
