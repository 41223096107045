import React, { useState ,useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import JustificationsActions from "~/store/ducks/justification/list";
import JustifyAlertActions from "~/store/ducks/justifyAlert/new";
import JustifyFileActions from "~/store/ducks/justifyFile/new";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SelectTest, Textarea, Input } from "~/components/Form";
import Modal from "~/components/Modal";
import { Button } from "~/styles/components";
import Text from "~/components/Text";
import Loading from "~/components/Loading";
import { CloseCircle } from "@styled-icons/evaicons-solid/";
import JustifyFileGetLinkActions from "~/store/ducks/justifyGetLink/get";
import * as FileSaver from "file-saver";

import fileImg from "~/assets/icons/file.svg"

import * as S from "./styles";

function ModalJustifyAlert({
  open,
  handleClose,
  onJustifySubmit,
  product_batch_alert_id,
}) {
  const dispatch = useDispatch();
  const formAction = useRef();

  const { data: justifies } = useSelector((state) => state.justifications);
  const { data: newFile, loading: loadingFiles } = useSelector((state) => state.justifyFile);
  const { data: fileGetLink, loading: loadingFileGetLink } = useSelector(
    (state) => state.justificationFileGetLink
  );

  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState();

  const close = () => {
    handleClose();
    dispatch(JustifyFileActions.reset());
    setFiles([]);
  };

  const handleFile = (fileName) => {
    const fileSplit = fileName.split("amazonaws.com/");
    const fileNameKey = fileSplit[1];
    setFileName(fileNameKey);
    dispatch(JustifyFileGetLinkActions.request(fileNameKey));
  };

  useEffect(() => {
    if(fileGetLink && fileName){
      
      fetch(fileGetLink)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, fileName));
      
      dispatch(JustifyFileGetLinkActions.reset(fileName));
      setFileName();
    }
  }, [fileGetLink]);

  const fectJustifications = useCallback(() => {
    dispatch(JustificationsActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectJustifications();
  }, [fectJustifications]);

  useEffect(() => {
    if(newFile) {
      const data = {
        id: newFile.id,
        name: newFile.name,
        path: newFile.path,
      };

      setFiles([...files, data]);
    } else {
      setFiles([]);
    }
  }, [dispatch, newFile]);

  async function handleSubmit(values) {
    if (!values.justify) {
      values.justify = null;
    }
    try {
      formAction.current.setErrors({});
      const schema = Yup.object().shape({
        justify: Yup.object().typeError("Selecione uma justificativa"),
        description: Yup.string(),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        product_batch_alert_id,
        justify_id: values.justify.id,
        description: values.description,
        files,
      };

      await dispatch(JustifyAlertActions.request(data));
      onJustifySubmit();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAction.current.setErrors(validationErrors);
      }
    }

    dispatch(JustifyFileActions.reset());
    setFiles([]);
  }

  async function insertFile(file) {
    const fileData = file[0];

    const data = new FormData();
    data.append('file', fileData);
    dispatch(JustifyFileActions.request(data));
  }

  return (
    <Modal open={open} close={close}>
      <S.Container>
        <S.Title>
          <Text id="justificativa" dm="Justificativa" />
        </S.Title>
        <S.BtnClose onClick={close}>
          <CloseCircle size="30" />
        </S.BtnClose>
        <Form ref={formAction} onSubmit={handleSubmit}>
          <S.Row>
            <SelectTest
              label={<Text id="justificativas" dm="Justificativas" />}
              name="justify"
              placeholder="Selecione"
              options={justifies && justifies.data}
            />
          </S.Row>
          <S.Row>
            <Textarea
              label={<Text id="observação" dm="Observação" />}
              id="description"
              name="description"
              maxlength={1000}
              cols="30"
              rows="10"
            />
          </S.Row>
          <S.Row>
            <Input
              label={<Text id="fileInput" dm="Anexar arquivo" />}
              id="file"
              name="file"
              type="file"
              onChange={(event) =>
                event.target.files[0] && insertFile(event.target.files)
              }
            />
          </S.Row>
          {loadingFiles ? (
            <Loading />
          ) : (
            <>
              {files && files[0] && (
                <S.FileList>
                  {files.map(file => (
                    <li key={file.id}>
                      <img src={fileImg} alt="file" onClick={() => {handleFile(file.path)}}/>
                      {file.name}
                    </li>
                  ))}
                </S.FileList>
              )}
            </>
          )}
          <S.WrapBtns>
            <Button type="submit" btStyle="primary">
              <Text id="salvar" dm="Justificar" />
            </Button>
          </S.WrapBtns>
        </Form>
      </S.Container>
    </Modal>
  );
}

export default ModalJustifyAlert;


