import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import UsersActions from "~/store/ducks/user/list";
import ResponsibleShowActions from "~/store/ducks/responsible/show";
import ResponsibleEditActions from "~/store/ducks/responsible/edit";
import ResponsibleDeleteActions from "~/store/ducks/responsible/delete";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { useParams } from "react-router";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input, SelectTest } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Responsible() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams();

  const [ responsible, setResponsible ] = useState(null);

  const { data: dataResponsible, loading } = useSelector(
    (state) => state.responsibleShow
  );
  const { data: dataUsers, loading: loadingUsers} = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(UsersActions.request());
    dispatch(ResponsibleShowActions.request(id));
  }, [id]);

  useEffect(() => {
    if(
      dataResponsible &&
      dataResponsible.data &&
      dataResponsible.data.user_id &&
      dataUsers &&
      dataUsers.data
    ) {
      const data = dataUsers.data.filter(item => item.id === dataResponsible.data.user_id)
      setResponsible(data[0]);
    } else {
      setResponsible(null);
    }
  }, [dataResponsible, dataUsers]);

  async function handleSubmit(values) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Nome de responsavel é obrigatório"),
        user: Yup.object().typeError("Selecione um usuário"),
      });
      await schema.validate(values, {
        abortEarly: false,
      });
      const data = {
        name: values.name,
        user_id: values.user.id,
      };
      await dispatch(ResponsibleEditActions.request(id, data, history));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDelete() {
    try {
      await dispatch(ResponsibleDeleteActions.request(id));
      history.push("/config/responsible");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="responsavel" dm="Responsável" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        {loading || loadingUsers || !(dataUsers && dataUsers.data) ? (
          <Loading />
        ) : (
          <Form
            ref={formLogin}
            onSubmit={handleSubmit}
            initialData={{ user: responsible, name: dataResponsible && dataResponsible.data.name }}
          >
            <WrapForm>
              <Row templatecolumns="1">
                <Row templatecolumns="1">
                  <Input
                    label={<Text id="nome" dm="Nome" />}
                    name="name"
                    placeholder="Digite o nome"
                  />
                </Row>
                <SelectTest
                  label={<Text id="user" dm="Usuário" />}
                  name="user"
                  placeholder="Selecione usuário"
                  options={dataUsers && dataUsers.data}
                />
              </Row>
            </WrapForm>
            <WrapBtns>
              <Button type="reset" onClick={handleDelete} btStyle="delete">
                <Text id="deletar" dm="Deletar" />
              </Button>
              <Button type="submit" btStyle="primary">
                <Text id="salvar" dm="Salvar" />
              </Button>
            </WrapBtns>
          </Form>
        )}
      </Content>
    </Container>
  );
}

export default Responsible;
