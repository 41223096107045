import React from "react";

import { Container, Logo } from "./styles";
import Menu from "./Menu";
import User from "./User";
import Language from "./Language";

function Header() {
  return (
    <Container>
      <Logo />
      <Menu />
      <span>
        <Language />
        <User />
      </span>
    </Container>
  );
}

export default Header;
