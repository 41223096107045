import styled from "styled-components";

export const Container = styled.div``;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  svg {
    margin-right: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;
