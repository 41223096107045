import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import SecondAprovalEditActions from "../../ducks/secondAproval/edit";

const getAuthData = (state) => state.auth.data;

export function* secondAprovalEdit(action) {
  try {
    const { id, data: patch } = action;
    console.log('@@@@@', action)
    console.log(id);
    const url = `second-aproval/${id}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.patch, url, patch, makeOptions(authData));

    yield put(SecondAprovalEditActions.success(data));
    notify("success", "Aprovação cadastrada!");
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(SecondAprovalEditActions.failure(err.response.data.error.message));
  }
}
