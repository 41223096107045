import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "~/store/ducks/auth";
import FilterActions from "~/store/ducks/filter";
import UserShowActions from "~/store/ducks/user/show";

import { Container, Name, Logout } from "./styles";
import { LogoutCircleR } from "@styled-icons/remix-line";

function User() {
  const dispatch = useDispatch();

  const { data: user } = useSelector((state) => state.userShow);

  const userLogout = () => {
    dispatch(AuthActions.reset());
    dispatch(UserShowActions.reset());
    dispatch(FilterActions.reset());
  };

  return (
    <Container>
      <Name>{user.usuario_nome}</Name>
      <Logout onClick={userLogout}>
        <LogoutCircleR size={20} />
      </Logout>
    </Container>
  );
}

export default User;
