import React from "react";

import { Container, Button } from "./styles";

export default function Paginator({ pagination, onPageChange, neighbors = 1 }) {
  function renderButtons() {
    if (!pagination) return <></>;

    const { total, perPage, page, lastPage } = pagination;

    const pages = lastPage;
    const targetSize = neighbors * 8; // quantos botões queremos a mais pra cada lado
    const middle = Math.floor(targetSize / 2);
    const paginatorArray = [];

    if (pages > 1) {
      let inicio = page > middle ? page - middle : 1;
      inicio = inicio > pages - targetSize ? pages - targetSize : inicio;
      inicio = inicio < 1 ? 1 : inicio;
      let t = inicio + targetSize;
      t = pages < t ? pages : t;
      for (let i = inicio; i <= t; i++) paginatorArray.push(i);
    }


    return (
      <Container>
        {page < 6 ? (
          ""
        ) : (
          <Button onClick={() => onPageChange(1)} className="firstlast">
            1
          </Button>
        )}

        {paginatorArray?.map((i) => (
          <Button active={page === i} key={i} onClick={() => onPageChange(i)}>
            {i}
          </Button>
        ))}
        {page === lastPage ? (
          ""
        ) : (
          <Button onClick={() => onPageChange(lastPage)} className="firstlast">
            {lastPage}
          </Button>
        )}
      </Container>
    );
  }

  return <>{renderButtons()}</>;
}
