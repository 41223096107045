import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions, queryBuilder } from "~/services/api";
import ProductBatchAlertExportActions from "../../ducks/productBatchAlert/export";

const getAuthData = (state) => state.auth.data;

export function* productBatchAlertExport(action) {
  try {
    const { params } = action;
    const query = params ? queryBuilder(params) : "";
    const url = `product-batch-alert?${query}&perPage=false`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.get, url, makeOptions(authData));
    yield put(ProductBatchAlertExportActions.success(data));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(ProductBatchAlertExportActions.failure(err.response.data.error.message));
  }
}
