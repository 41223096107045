import React, { useRef, useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import UsersActions from "~/store/ducks/user/list";
import InventarioGroupShowActions from "~/store/ducks/inventarioGroup/show";
import InventarioGroupEditActions from "~/store/ducks/inventarioGroup/edit";
import InventarioGroupDeleteActions from "~/store/ducks/inventarioGroup/delete";
import InventarioGroupUserActions from "~/store/ducks/inventarioGroupUser/new";
import InventarioGroupUserDeleteActions from "~/store/ducks/inventarioGroupUser/delete";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { useParams } from "react-router";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input, SelectTest } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../../styles";
import { UserList, UserItem } from './styles';
import { ButtonMini, Button } from "~/styles/components";

function Group() {
  const formGroup = useRef();
  const formGroupUser = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams();

  const { data: dataGroup, loading: loadingGroup } = useSelector(
    (state) => state.inventarioGroupShow
  );
  const { data: dataUsers, loading: loadingUser} = useSelector(
    (state) => state.users
  );

  const fectData = useCallback(() => {
    dispatch(InventarioGroupShowActions.request(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(UsersActions.request());
    fectData();
  }, [fectData]);

  async function handleSubmit(data) {
    try {
      formGroup.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Digite o nome do grupo"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await dispatch(InventarioGroupEditActions.request(id, data));
      history.push("/config/group");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formGroup.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDelete() {
    try {
      await dispatch(InventarioGroupDeleteActions.request(id));
      history.push("/config/group");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formGroup.current.setErrors(validationErrors);
      }
    }
  }

  async function handleAddGroupUser(values) {
    try {
      formGroupUser.current.setErrors({});
      const schema = Yup.object().shape({
        user: Yup.object().typeError("Selecione um usuário"),
      });
      await schema.validate(values, {
        abortEarly: false,
      });

      const data = {
        inventario_group_id: id,
        user_id: values.user.id,
      };

      await dispatch(InventarioGroupUserActions.request(data));
      fectData();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formGroupUser.current.setErrors(validationErrors);
      }
    }
  }

  async function handleDeleteGroupUser(group_user_id) {
    try {
      await dispatch(InventarioGroupUserDeleteActions.request(group_user_id));
      fectData();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formGroupUser.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="group" dm="Grupo" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        {loadingGroup ? (
          <Loading />
        ) : (
          <>
            <Form ref={formGroup} onSubmit={handleSubmit}>
              <WrapForm>
                <Row templatecolumns="1">
                  <Input
                    label={<Text id="group" dm="Grupo" />}
                    name="name"
                    defaultValue={dataGroup.data.name}
                  />
                </Row>
              </WrapForm>
              <WrapBtns>
                <Button type="reset" onClick={handleDelete} btStyle="delete">
                  <Text id="deletar" dm="Deletar" />
                </Button>
                <Button type="submit" btStyle="primary">
                  <Text id="salvar" dm="Salvar" />
                </Button>
              </WrapBtns>
            </Form>
            <UserList>
              <SubTitle>
                <Text id="users" dm="Usuários" />
              </SubTitle>
              {
                dataGroup &&
                dataGroup.data.inventario_group_users &&
                dataGroup.data.inventario_group_users[0] &&
                dataGroup.data.inventario_group_users.map(item => (
                  dataGroup.data.name == "Armazém" ?
                  (<Link to={{
                    pathname: `/config/plant-association/${item.user.usuario_id}`,
                    state: {
                      name: item.user.usuario_nome
                    }
                  }} key={item.id}>
                    <UserItem>
                      <strong>{item.user.usuario_nome}</strong>

                      <Button type="reset" onClick={() => handleDeleteGroupUser(item.id)} btStyle="delete">
                        <Text id="deletar" dm="Deletar" />
                      </Button>
                    </UserItem>
                  </Link>)
                  :
                  <UserItem key={item.id}>
                      <strong>{item.user.usuario_nome}</strong>
                      <Button type="reset" onClick={() => handleDeleteGroupUser(item.id)} btStyle="delete">
                        <Text id="deletar" dm="Deletar" />
                      </Button>
                    </UserItem>
                ))
              }
            </UserList>
            {
              loadingUser ? (
                <Loading />
              ) : (
              <>
                <Form ref={formGroupUser} onSubmit={handleAddGroupUser}>
                  <WrapForm>
                    <Row templatecolumns="1">
                      <SelectTest
                        label={<Text id="user" dm="Usuário" />}
                        name="user"
                        placeholder="Selecione usuário"
                        options={dataUsers && dataUsers.data}
                      />
                    </Row>
                  </WrapForm>
                  <WrapBtns>
                    <Button type="submit" btStyle="primary">
                      <Text id="add" dm="Adicionar" />
                    </Button>
                  </WrapBtns>
                </Form>
              </>
              )
            }
          </>
        )}
      </Content>
    </Container>
  );
}

export default Group;
