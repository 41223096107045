import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { useHistory,  useParams, useLocation } from "react-router-dom";
import api, {makeOptions} from "~/services/api";
import { Form } from "@unform/web";
import {  useSelector } from "react-redux";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { notify } from "~/services/notification";
import Header from "~/components/Header";
import { SelectTest } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { UserList, UserItem } from './styles';
import { ButtonMini, Button } from "~/styles/components";

function PlantAssociation() {
  const [userPlants, setUserPlants] = useState([])

  const history = useHistory();
  const { id } = useParams();
  const {state} = useLocation();

  const userAuth = useSelector((state) => state.auth.data)
  const formLinkPlantToUser = useRef();

  const fetchUserPlants = useCallback(async () => {
    try {
      const {data: response } = await api.get(`user-plant/${id}`, makeOptions(userAuth))

      setUserPlants(response.data ?? [])
    } catch(err) {
      notify("error", err.message);
    }
  }, [id])

  const deleteUserPlant = useCallback(async (plantName) => {
    try {
      await api.delete(`user-plant/${id}/${plantName}`, makeOptions(userAuth))

      notify("success", "Removido");

      fetchUserPlants()
    } catch(err) {
      notify("error", err.message);
    }
  }, [id, fetchUserPlants])

  const linkPlantToUser = useCallback(async (values) => {
     try {

      const plant = values.plant

      if(userPlants.find(i => i.plant_name === plant.id)) {
        return notify("error", "Essa planta já está atribuida a este usuário.");
      }

      const {data} = await api.post('user-plant', {
        user_id: Number(id),
        plant_name: plant.id
      } ,makeOptions(userAuth))
      notify("success", "Concluido");

      console.log("response", data)

      fetchUserPlants()
    } catch(err) {
      notify("error", err.message);
    }
  }, [id, fetchUserPlants, userPlants])

  useEffect(() => {
    fetchUserPlants()
  }, [fetchUserPlants])

  const options = useMemo(() => {
    const allOptions = [
      {
        id: "C327",
        name: "C327 - TRINIDAD"
      },
      {
        id: "A926",
        name: "A926 - MINGA GUAZU"
      },
      {
        id: "L231",
        name: "L231 - Uberaba"
      },
      {
        id: "BR70",
        name: "BR70 - LEM Golden",
      },
      {
        id: "B144",
        name: "B144 - LEM Luft",
      },
      {
        id: "B464",
        name: "B464 - CUIABA",
      },
      {
        id: "WR46",
        name: "WR46 - CUIABA",
      },
      {
        id: "C039",
        name: "C039 - Terloc",
      },
      {
        id: "C034",
        name: "C034 - Vopak",
      },
      {
        id: "C036",
        name: "C036 - Vopak",
      },
      {
        id: "C037",
        name: "C037 - Local Frio",
      },
      {
        id: "C038",
        name: "C038 - Local Frio",
      },
      {
        id: "C035",
        name: "C035 - LUFT BARUERI",
      },
      {
        id: "XB61",
        name: "XB61 - BARRA MANSA",
      },
      {
        id: "L031",
        name: "L031 - PAULINIA",
      },
      {
        id: "BR52",
        name: "BR52 - PAULINIA",
      },
      {
        id: "WR48",
        name: "WR48 - GOIANIA",
      },
      {
        id: "C030",
        name: "C030 - LUFT IBIPORA",
      },
      {
        id: "WR47",
        name: "WR47 - IBIPORA",
      },
      {
        id: "C031",
        name: "C031 - LUFT CARAZINHO",
      },
      {
        id: "BR79",
        name: "BR79 - CARAZINHO",
      },
      {
        id: "XB95",
        name: "XB95 - UBERABA",
      },
      {
        id: "XB94",
        name: "XB94 - BALSAS",
      },
    ];

    const filtered = allOptions.filter((opt) => {
      const exist = userPlants.find((p) => p.plant_name === opt.name)

      return !exist
    })

    return filtered

  } , [userPlants])


  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <p>{state.name}</p>
          <small>
            <Text id="group" dm="Associar planta ao usuário" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
          <>
            <UserList style={{borderTop: 'none', marginTop: 0, paddingTop: 0}}>
              <SubTitle>
                <Text id="plant_association" dm="Plantas associadas ao usuário" />
              </SubTitle>
              {userPlants.map(item => (
                  <UserItem key={`user_plant_${item.plant_name}_user_id_${item.user_id}`}>
                    <strong>{item.plant_name}</strong>

                    <Button type="reset" btStyle="delete" onClick={() => deleteUserPlant(item.plant_name)}>
                      <Text id="deletar" dm="Deletar" />
                    </Button>
                  </UserItem>
                ))
              }
            </UserList>
              <>
                <Form onSubmit={linkPlantToUser} ref={formLinkPlantToUser}>
                  <WrapForm>
                    <Row templatecolumns="1">
                      <SelectTest
                        label={<Text id="plant" dm="Associar Plantas" />}
                        name="plant"
                        placeholder="Selecione uma planta"
                        options={options}
                      />
                    </Row>
                  </WrapForm>
                  <WrapBtns>
                    <Button type="submit" btStyle="primary">
                      <Text id="add" dm="Adicionar" />
                    </Button>
                  </WrapBtns>
                </Form>
              </>
          </>
      </Content>
    </Container>
  );
}

export default PlantAssociation;
