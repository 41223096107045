import { call, put } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api from "~/services/api";
import AuthActions from "../../ducks/auth";
import UserShowActions from "../../ducks/user/show";

export function* login(action) {
  try {
    const { data: post, history } = action;
    const { data } = yield call(api.post, "login", post);

    notify("success", "Olá seja bem-vindo!");
    if (history) {
      history.push("/");
    }
    // yield put(AuthActions.success({ ...data.user[0], token: data.token }));
    yield put(UserShowActions.success(data.user));
    yield put(AuthActions.success(data.token));
  } catch (err) {
    notify("error", err.response.data.error.message);
    yield put(AuthActions.failure(err.response.data.error.message));
  }
}
