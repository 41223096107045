import React from "react";
import { useFormatMessage } from "react-intl-hooks";

// import { Container } from './styles';

function Text({ id, dm }) {
  const text = useFormatMessage();
  return <>{text({ id: id, defaultMessage: dm })}</>;
}

export default Text;
