import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import InventoryHistoryDeleteActions from "../../ducks/inventoryHistory/delete";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* inventarioHistoryDelete(action) {
  try {
    const { date } = action;
    const url = `/inventario-history/delete-by-month/${date}`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.delete, url, makeOptions(authData));

    if (data.erro) {
      throw new Error(data.message);
    }

    yield put(InventoryHistoryDeleteActions.success(data));
    const { data: language } = yield select(getLanguage);

    const { lastSap, lastWms } = data;

    if (lastSap || lastWms) {
      notify(
        "success",
        "Os registros deste mês foram excluídos",
        "inventory.history.deleted",
        language
      );
    } else {
      notify(
        "success",
        "Nenhum registro foi encontrado para este mês.",
        "inventory.history.empty",
        language
      );
    }
  } catch (err) {
    notify("error", err?.response?.data?.error?.message || err.message);
    yield put(
      InventoryHistoryDeleteActions.failure(
        err?.response?.data?.error?.message || err.message
      )
    );
  }
}
