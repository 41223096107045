import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form } from "@unform/web";
import Modal from "~/components/Modal";
import Text from "~/components/Text";
import { CloseCircle } from "@styled-icons/evaicons-solid/";
import * as S from "./styles";
import fileImg from "~/assets/icons/file.svg";
import Button from "../../../Button";
import { useDispatch, useSelector } from "react-redux";
import JustifyFileGetLinkActions from "~/store/ducks/justifyGetLink/get";
import * as FileSaver from "file-saver";

function ModalAlert({ open, handleClose, data }) {

  const dispatch = useDispatch();
  
  const [disableBtnToPrint, setDisableBtnToPrint] = useState(false);

  const [fileNameAlertKey, setFileNameAlertKey] = useState();

  const { data: fileGetLink, loading: loadingFileGetLink } = useSelector(
    (state) => state.justificationFileGetLink
  );

  const handleFile = (fileName) => {
    const fileSplit = fileName.split("amazonaws.com/");
    const fileKey = fileSplit[1];
    
    setFileNameAlertKey(fileKey);
    dispatch(JustifyFileGetLinkActions.request(fileKey));
  };

  useEffect(() =>{
    if(fileGetLink && fileNameAlertKey){
      fetch(fileGetLink)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, fileNameAlertKey));
      
      dispatch(JustifyFileGetLinkActions.reset());
      setFileNameAlertKey();
    }
  }, [fileGetLink]);

  const close = () => {
    handleClose();
  };

  const handlePrint = () => {
    setDisableBtnToPrint(true);
    setTimeout(() => window.print(), 250);
    setTimeout(() => setDisableBtnToPrint(false), 1000);
  };

  return (
    <Modal open={open} close={close}>
      {data && (
        <S.Container>
          <S.HeaderTitle>{data.productBatch.product.name}</S.HeaderTitle>
          {data.justify &&
            data.justify.first_aproval &&
            data.justify.first_aproval.status === "FINALIZADO" &&
            !disableBtnToPrint && (
              <Button
                style={{ float: "right" }}
                onClick={handlePrint}
                color={"#25a4cd"}>
                Download PDF
              </Button>
            )}
          <S.BtnClose onClick={close}>
            {!disableBtnToPrint && <CloseCircle size="30" />}
          </S.BtnClose>
          <Form>
            {data.justify && (
              <>
                <S.Wrapper>
                  <S.Title>
                    <Text id="justificativa" dm="Justificativa" />
                  </S.Title>
                  <S.Row templatecolumns="2">
                    <p>
                      <strong>
                        <Text id="descrição" dm="Descrição" />: <br />
                      </strong>
                      {data.justify.justify.name || "-"}
                    </p>
                    <p>
                      <strong>
                        <Text id="date" dm="Data" />: <br />
                      </strong>
                      {moment(data.justify.created_at).format("DD/MM/YYYY") ||
                        "-"}
                    </p>
                  </S.Row>
                  <S.Row templatecolumns="2">
                    <p>
                      <strong>
                        <Text id="observacao" dm="Observaçao" />: <br />
                      </strong>
                      {data.justify.description || "-"}
                    </p>
                    <p>
                      <strong>
                        <Text id="user" dm="Usuário" />: <br />
                      </strong>
                      {(data.justify.user && data.justify.user.usuario_nome) ||
                        "-"}
                    </p>
                  </S.Row>
                  {data.justify.justify_files && data.justify.justify_files[0] && (
                    <S.Row>
                      <p>
                        <strong>
                          <Text id="files" dm="Arquivos" />:{" "}
                        </strong>
                      </p>
                      <S.FileList>
                        {data.justify.justify_files.map((file) => (
                          <li key={file.path} onClick={() =>{ handleFile(file.path)}}>
                            {/* <a href={file.path} target="blank"> */}
                              <S.FileIcon />
                              {`${file.name.substring(0, 10)}${
                                file.name.length >= 10 ? "..." : ""
                              }`}
                            {/* </a> */}
                          </li>
                        ))}
                      </S.FileList>
                    </S.Row>
                  )}
                </S.Wrapper>
                {data.justify.first_aproval && (
                  <>
                    <S.Wrapper>
                      <S.Title>
                        <Text id="4plApproval" dm="Aprovação 4PL" />
                      </S.Title>
                      <S.Row templatecolumns="2">
                        <p>
                          <strong>
                            <Text id="status" dm="Status" />: <br />
                          </strong>
                          {data.justify && data.justify.first_aproval
                            ? data.justify.first_aproval.status
                            : "-"}
                        </p>
                        <p>
                          <strong>
                            <Text id="data" dm="Data" />: <br />
                          </strong>
                          {data.justify && data.justify.first_aproval
                            ? moment(
                                data.justify.first_aproval.created_at
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </p>
                      </S.Row>
                      <S.Row templatecolumns="2">
                        <p>
                          <strong>
                            <Text id="descricao" dm="Descrição" />: <br />
                          </strong>
                          {data.justify &&
                          data.justify.first_aproval &&
                          data.justify.first_aproval.description
                            ? data.justify.first_aproval.description
                            : "-"}
                        </p>
                        <p>
                          <strong>
                            <Text id="user" dm="Usuário" />: <br />
                          </strong>
                          {data.justify &&
                          data.justify.first_aproval &&
                          data.justify.first_aproval.user
                            ? data.justify.first_aproval.user.usuario_nome
                            : "-"}
                        </p>
                      </S.Row>
                      {data.justify.first_aproval.justify_files &&
                        data.justify.first_aproval.justify_files[0] && (
                          <S.Row>
                            <p>
                              <strong>
                                <Text id="files" dm="Arquivos" />:{" "}
                              </strong>
                            </p>
                            <S.FileList>
                              {data.justify.first_aproval.justify_files.map(
                                (file) => (
                                  <li key={file.path}>
                                    <a href={file.path} target="blank">
                                      <S.FileIcon />
                                      {`${file.name.substring(0, 10)}${
                                        file.name.length >= 10 ? "..." : ""
                                      }`}
                                    </a>
                                  </li>
                                )
                              )}
                            </S.FileList>
                          </S.Row>
                        )}
                    </S.Wrapper>
                    {data.justify.first_aproval.second_aproval && (
                      <>
                        <S.Wrapper>
                          <S.Title>
                            <Text id="cortevaApproval" dm="Aprovação Corteva" />
                          </S.Title>
                          <S.Row templatecolumns="2">
                            <p>
                              <strong>
                                <Text id="status" dm="Status" />: <br />
                              </strong>
                              {data.justify &&
                              data.justify.first_aproval &&
                              data.justify.first_aproval.second_aproval &&
                              data.justify.first_aproval.second_aproval.status
                                ? data.justify.first_aproval.second_aproval
                                    .status
                                : "-"}
                            </p>
                            <p>
                              <strong>
                                <Text id="data" dm="Data" />: <br />
                              </strong>
                              {(data.justify.first_aproval.second_aproval
                                .corteva_responsible &&
                                moment(
                                  data.justify.first_aproval.second_aproval
                                    .corteva_responsible.created_at
                                ).format("DD/MM/YYYY")) ||
                                "-"}
                            </p>
                          </S.Row>
                          <S.Row templatecolumns="2">
                            <p>
                              <strong>
                                <Text id="responsavel" dm="Responsável" />:{" "}
                                <br />
                              </strong>
                              {(data.justify.first_aproval.second_aproval
                                .corteva_responsible &&
                                data.justify.first_aproval.second_aproval
                                  .corteva_responsible.name) ||
                                "-"}
                            </p>
                            <p>
                              <strong>
                                <Text
                                  id="corteva_dead_line"
                                  dm="Prazo de aprovação"
                                />
                                : <br />
                              </strong>
                              {(data.justify.first_aproval.second_aproval
                                .corteva_dead_line &&
                                moment(
                                  data.justify.first_aproval.second_aproval
                                    .corteva_dead_line
                                ).format("DD/MM/YYYY")) ||
                                "-"}
                            </p>
                          </S.Row>
                          <S.Row templatecolumns="1">
                            <p>
                              <strong>
                                <Text id="descricao" dm="Descrição" />: <br />
                              </strong>
                              {data.justify &&
                              data.justify.first_aproval &&
                              data.justify.first_aproval.second_aproval &&
                              data.justify.first_aproval.second_aproval
                                .description
                                ? data.justify.first_aproval.second_aproval
                                    .description
                                : "-"}
                            </p>
                          </S.Row>
                          {data.justify.first_aproval.second_aproval
                            .justify_files &&
                            data.justify.first_aproval.second_aproval
                              .justify_files[0] && (
                              <S.Row>
                                <p>
                                  <strong>
                                    <Text id="files" dm="Arquivos" />:{" "}
                                  </strong>
                                </p>
                                <S.FileList>
                                  {data.justify.first_aproval.second_aproval.justify_files.map(
                                    (file) => (
                                      <li key={file.path}>
                                        <a href={file.path} target="blank">
                                          <S.FileIcon />
                                          {`${file.name.substring(0, 15)}${
                                            file.name.length >= 15 ? "..." : ""
                                          }`}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </S.FileList>
                              </S.Row>
                            )}
                        </S.Wrapper>
                        {data.justify.sap_code && (
                          <S.Wrapper>
                            <S.Title>
                              <Text id="4plCompletion" dm="Finalização" />
                            </S.Title>
                            <S.Row templatecolumns="2">
                              <p>
                                <strong>
                                  <Text id="sapCode" dm="Código SAP" />:{" "}
                                </strong>
                                {data.justify.sap_code || "-"}
                              </p>
                            </S.Row>
                            <S.Row>
                              <p>
                                <strong>
                                  <Text id="user" dm="Usuário" />: <br />
                                </strong>
                                {data.justify && data.justify.user_sap
                                  ? data.justify.user_sap.usuario_nome
                                  : "-"}
                              </p>
                            </S.Row>
                          </S.Wrapper>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Form>
        </S.Container>
      )}
    </Modal>
  );
}

export default ModalAlert;
