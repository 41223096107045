import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import WmsImportActions from "../../ducks/importWms/new";
import SapImportStatus from "../../ducks/importSap/list";
import moment from "moment";

const getAuthData = (state) => state.auth.data;

export function* createWmsImport(action) {
  try {
    const { post, history } = action;
    const { file, route } = post;
    const url = `inventario-history/import-${route}/`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, file, makeOptions(authData));

    const response = yield put(WmsImportActions.success(data));
    const { DATA: msg } = response.data;
    notify("success", msg);

    if (history) {
      history.push("/");
    }
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(WmsImportActions.failure(err.response.data.error.message));
  }
}

export function* deleteWsmImport(action) {
  try {
    const { route, name } = action;

    const dateFilter = moment.utc().format("YYYY-MM");

    const url = `inventario-history/delete-import-${route}?import_date=${dateFilter}&name=${name}`;

    const authData = yield select(getAuthData);

    const { data } = yield call(api.post, url, {}, makeOptions(authData));

    if ("SUCCESS" in data) {
      notify(data.SUCCESS ? "success" : "error", data.MSG);
    } else {
      notify("info", data.MSG);
    }

    yield put(WmsImportActions.deleted(data.error));

    yield put(SapImportStatus.request(`import_date=${dateFilter}`));


  } catch (err) {
    yield put(WmsImportActions.deleted());
    notify("error", "FALHA AO EXCLUIR IMPORTAÇÃO SAP");
    yield put(WmsImportActions.failure(err.response.data.error.message));
  }
}
