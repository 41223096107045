import { combineReducers } from "redux";

import { reducer as auth } from "./auth";

import { reducer as filter } from "./filter";

import { reducer as users } from "./user/list";
import { reducer as userShow } from "./user/show";

import { reducer as justification } from "./justification/new";
import { reducer as justifications } from "./justification/list";
import { reducer as justificationEdit } from "./justification/edit";
import { reducer as justificationShow } from "./justification/show";
import { reducer as justificationDelete } from "./justification/delete";

import { reducer as responsible } from "./responsible/new";
import { reducer as responsibles } from "./responsible/list";
import { reducer as responsibleEdit } from "./responsible/edit";
import { reducer as responsibleShow } from "./responsible/show";
import { reducer as responsibleDelete } from "./responsible/delete";

import { reducer as productBatch } from "./productBatch/new";
import { reducer as productBatches } from "./productBatch/list";
import { reducer as productBatchEdit } from "./productBatch/edit";
import { reducer as productBatchShow } from "./productBatch/show";
import { reducer as productBatchDelete } from "./productBatch/delete";

import { reducer as inventarioGroup } from "./inventarioGroup/new";
import { reducer as inventarioGroups } from "./inventarioGroup/list";
import { reducer as inventarioGroupEdit } from "./inventarioGroup/edit";
import { reducer as inventarioGroupShow } from "./inventarioGroup/show";
import { reducer as inventarioGroupDelete } from "./inventarioGroup/delete";

import { reducer as inventarioGroupUser } from "./inventarioGroupUser/new";
import { reducer as inventarioGroupUsers } from "./inventarioGroupUser/list";
import { reducer as inventarioGroupUserDelete } from "./inventarioGroupUser/delete";

import { reducer as productBatchAlerts } from "./productBatchAlert/list";
import { reducer as productBatchAlertShow } from "./productBatchAlert/show";
import { reducer as productBatchAlertEdit } from "./productBatchAlert/edit";
import { reducer as productBatchAlertIndicators } from "./productBatchAlert/indicators";
import { reducer as productBatchAlertExport } from "./productBatchAlert/export";

import { reducer as sapImportCreate } from "./importSap/new";
import { reducer as sapImports } from "./importSap/list";

import { reducer as wmsImportCreate } from "./importWms/new";
import { reducer as wmsImports } from "./importWms/list";

import { reducer as firstAproval } from "./firstAproval/new";
import { reducer as firstAprovalFast } from "./firstAproval/fast";

import { reducer as cortevaResponsible } from "./cortevaResponsible/new";

import { reducer as secondAprovalEdit } from "./secondAproval/edit";

import { reducer as justifyAlert } from "./justifyAlert/new";

import { reducer as justifyFile } from "./justifyFile/new";

import { reducer as language } from "./language";

import { reducer as inventoryHistoryDelete } from "./inventoryHistory/delete";

import { reducer as justificationFileGetLink } from "./justifyGetLink/get";

const reducers = combineReducers({
  auth,
  filter,
  users,
  userShow,
  justification,
  justifications,
  justificationEdit,
  justificationShow,
  justificationDelete,
  responsible,
  responsibles,
  responsibleEdit,
  responsibleShow,
  responsibleDelete,
  justifyAlert,
  justifyFile,
  firstAproval,
  firstAprovalFast,
  secondAprovalEdit,
  cortevaResponsible,
  productBatch,
  productBatches,
  productBatchEdit,
  productBatchShow,
  productBatchDelete,
  productBatchAlerts,
  productBatchAlertShow,
  productBatchAlertEdit,
  productBatchAlertIndicators,
  productBatchAlertExport,
  inventarioGroup,
  inventarioGroups,
  inventarioGroupEdit,
  inventarioGroupShow,
  inventarioGroupDelete,
  inventarioGroupUser,
  inventarioGroupUsers,
  inventarioGroupUserDelete,
  sapImportCreate,
  sapImports,
  wmsImportCreate,
  wmsImports,
  inventoryHistoryDelete,
  language,
  justificationFileGetLink,
});

export default reducers;
