import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { notify } from "~/services/notification";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import SapImportsActions from "~/store/ducks/importSap/list";
import SapImportCreateActions from "~/store/ducks/importSap/new";
import WmsImportCreateActions from "~/store/ducks/importWms/new";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";
import { Trash } from "@styled-icons/ionicons-outline/Trash";
import api, { makeOptions } from "~/services/api";

import XLSX from "xlsx";

import Header from "~/components/Header";
import { Input } from "~/components/Form";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapForm,
  WSMWrapperTitle,
  DeleteImport,
  AlignCenter,
} from "../styles";
import { StatusBar, StatusItem, ImportContainer, List, Item } from "./styles";
import { ButtonMini } from "~/styles/components";

function Responsible() {
  const sapDowForm = useRef();
  const sapDupontForm = useRef();
  const wmsDowForm = useRef();
  const wmsDupontForm = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState([]);
  const [sapDowActive, setSapDowActive] = useState(false);
  const [sapDowImportDate, setSapDowImportDate] = useState(null);
  const [sapDupontActive, setSapDupontActive] = useState(false);
  const [sapDupontImportDate, setSapDupontImportDate] = useState(null);
  const [wmsActive, setWmsActive] = useState(false);
  const [wmsDowActive, setWmsDowActive] = useState(false);
  const [wmsDupontActive, setWmsDupontActive] = useState(false);
  const [wmsImportDate, setWmsImportDate] = useState(null);
  const [userPlants, setUserPlants] = useState([])
  // const [mouthPlants, setMountPlants] = useState([])

  const { data: importSap, loading: loadingImportSap } = useSelector(
    (state) => state.sapImportCreate
  );
  const { data: status, loading: loadingStatus } = useSelector(
    (state) => state.sapImports
  );
  const { data: importWms, loading: loadingImportWms } = useSelector(
    (state) => state.wmsImportCreate
  );
  const { data: user } = useSelector((state) => state.userShow);
  const userAuth = useSelector((state) => state.auth.data)

  // administradora-cd - root

  const isRoot = useMemo(() => {
    return user.usuario_tipo.toLowerCase() === 'root'
  } , [user])

  const isAdmCD = useMemo(() => {
    return user.usuario_tipo.toLowerCase() === 'administradora-cd'
  } , [user])

  const fetchUserPlants = useCallback(async () => {
    try {
      const {data: response } = await api.get(`user-plant/${user.usuario_id}`, makeOptions(userAuth))

      setUserPlants(response.data ?? [])
    } catch(err) {
      notify("error", err.message);
    }
  }, [user.usuario_id])

  const fetchImport = useCallback(() => {
    dispatch(
      SapImportsActions.request(`import_date=${moment.utc().format("YYYY-MM")}`)
    );
  }, [dispatch]);

  useEffect(() => {
    fetchImport();
  }, [fetchImport]);

  useEffect(() => {
    fetchUserPlants()
  } , [fetchUserPlants])

  useEffect(() => {
    if (status) {
      if (status.sap_dow) {
        setSapDowActive(true);
        setSapDowImportDate(status.sap_dow.created_at);
      } else {
        setSapDowActive(false);
        setSapDowImportDate(null);
      }

      if (status.sap_dupont) {
        setSapDupontActive(true);
        setSapDupontImportDate(status.sap_dupont.created_at);
      } else {
        setSapDupontActive(false);
        setSapDupontImportDate(null);
      }

      if (status.wms_list && status.wms_list[0]) {
        const list = status.wms_list;
        if (list && list[0]) {
          setWmsActive(true);
          setWmsImportDate(list[0].created_at);
        }

        const branchesImported = new Array();
        list.forEach((item) => {
          item.branches.forEach((branche) => {
            branchesImported.push(branche);
          });
        });

        if (user.branches && user.branches[0]) {
          const branches = user.branches.map((branche) => ({
            id: branche,
            active: false,
          }));
          branchesImported.forEach((branche) => {
            branches.forEach((item) => {
              if (item.id === branche.id) {
                item.active = true;
              }
            });
          });

          const wmsActives = branches.filter((item) => !item.active);
          if (!(wmsActives && wmsActives[0])) {
            setWmsDowActive(true);
            setWmsDupontActive(true);
          }
        }
      }
    }
  }, [status]);

  const getWorkbookFromFile = async (excelFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary", cellDates: true });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleSapDowImport = async (file) => {
    const route = "dow";
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "csv" ||
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });
      await sendSap(newFileData, route);
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
    }
  };

  const handleSapDupontImport = async (file) => {
    const route = "dupont";
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "csv" ||
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });
      await sendSap(newFileData, route);
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
    }
  };

  const handleWmsDowImport = async (file) => {
    const route = "dow";
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "csv" ||
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });

      const firstPlant = newFileData[0]

      if (isRoot) {
        return await sendWms(newFileData, route);
      }

      if (!isRoot && isAdmCD && !!userPlants.find(i => i.plant_name === firstPlant.Planta)) {
        return await sendWms(newFileData, route);
      }

      return notify("error", "VOCÊ NÃO PODE REALIZAR A IMPORTAÇÃO PARA ESSA PLANTA");
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
    }
  };

  const handleWmsDupontImport = async (file) => {
    const route = "dupont";
    const name = file[0].name.split(".");
    const index = name.length - 1;
    if (
      name[index] === "csv" ||
      name[index] === "xlsx" ||
      name[index] === "xls" ||
      name[index] === "XLSX" ||
      name[index] === "XLS"
    ) {
      setFileData([]);
      const workbook = await getWorkbookFromFile(file[0]);
      let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const newFileData = await XLSX.utils.sheet_to_json(first_worksheet, {
        header: 0,
      });

      const firstPlant = newFileData[0]

      if (isRoot) {
        return await sendWms(newFileData, route);
      }

      if (!isRoot && isAdmCD && !!userPlants.find(i => i.plant_name === firstPlant.Planta)) {
        return await sendWms(newFileData, route);
      }

      return notify("error", "VOCÊ NÃO PODE REALIZAR A IMPORTAÇÃO PARA ESSA PLANTA");
    } else {
      setFileData([{ error: "NÃO É UM ARQUIVO VÁLIDO!" }]);
    }
  };

  const sendSap = async (newFileData, route) => {
    try {
      notify("success", "IMPORTAÇÃO INICIADA! AGUARDE.");
      dispatch(SapImportCreateActions.request({ file: newFileData, route }));
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSapDow = () => {

    const exists = status.wms_list.some((e)=>{
      return e.product_batche.last_inventario_history.legacy === 'dow'
    });

    if (wmsActive && exists) {
      return notify(
        "info",
        "PARA EXCLUIR A PLANILHA SAP, É PRECISO ANTES EXCLUIR A PLANILHA WMS."
      );
    };

    dispatch(SapImportCreateActions.delete("dow"));
  };

  const deleteSapDupon = () => {

    const exists = status.wms_list.some((e)=>{
      return e.product_batche.last_inventario_history.legacy === 'dupont'
    });

    if (wmsActive && exists) {
      return notify(
        "info",
        "PARA EXCLUIR A PLANILHA SAP, É PRECISO ANTES EXCLUIR A PLANILHA WMS."
      );
    };

    dispatch(SapImportCreateActions.delete("dupont"));
  };

  const deleteWsmImport = (legacy, name) => {
    dispatch(WmsImportCreateActions.delete(legacy, name));
  };

  const sendWms = async (newFileData, route) => {
    try {
      dispatch(WmsImportCreateActions.request({ file: newFileData, route }));
      notify("success", "IMPORTAÇÃO INICIADA! AGUARDE.");
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="import.title" dm="Import" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="import.upload" dm="Subir arquivo" />
        </SubTitle>
        <StatusBar>
          <StatusItem active={sapDowActive}>
            {sapDowActive && (
              <AlignCenter>
                <small>
                  <Text id="import.msg" dm="Importado em" />{" "}
                  {sapDowImportDate && (
                    <>{moment(sapDowImportDate).format("DD/MM")}</>
                  )}
                </small>
                {isRoot ? (
                <DeleteImport onClick={deleteSapDow}>
                  <Trash size={24} />
                </DeleteImport>
                ) : <p />}
              </AlignCenter>
            )}
            <strong>SAP DOW</strong>
          </StatusItem>
          <StatusItem active={sapDupontActive}>
            {sapDupontActive && (
              <AlignCenter>
                <small>
                  <Text id="import.msg" dm="Importado em" />{" "}
                  {sapDupontImportDate && (
                    <>{moment(sapDupontImportDate).format("DD/MM")}</>
                  )}
                </small>
                {isRoot ? <DeleteImport onClick={deleteSapDupon}>
                  <Trash size={24} />
                </DeleteImport> : <p />}
              </AlignCenter>
            )}

            <strong>SAP DUPONT</strong>
          </StatusItem>
        </StatusBar>
        {status && status.wms_list && status.wms_list[0] && (
          <>
            <WSMWrapperTitle>
              <SubTitle>
                <Text id="import.wmsList" dm="Importações anteriores WMS" />
              </SubTitle>
            </WSMWrapperTitle>
            <List>
              <header>
                <p>
                  <Text id="inventory.plant" dm="Planta" />
                </p>
                <p>
                  <Text id="inventory.branch" dm="Armazém" />
                </p>
                <p>
                  <Text id="inventory.importDate" dm="Data de importação" />
                </p>
                <p>
                  <Text id="empresa" dm="Empresa" />
                </p>
                <p>
                  <Text id="user" dm="Usuário" />
                </p>
                <p />
              </header>
              {status.wms_list.map((item) => (
                <Item key={item.id}>
                  <strong>{item.name}</strong>
                  <strong>
                    {item.branches && item.branches[0] ? (
                      <>
                        {item.branches.map(
                          (branche, index) =>
                            `${index > 0 ? "-" : ""}${branche.company_name}`
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </strong>                  
                  <strong>
                    { item.product_batche.last_inventario_history ?
                    moment(
                      item.product_batche.last_inventario_history.created_at
                    ).format("DD/MM/YYYY") : '-'
                    }
                  </strong>
                    <strong>
                      {
                      item.product_batche.last_inventario_history ?
                      item.product_batche.last_inventario_history.legacy.toUpperCase():'-'
                      }
                    </strong>
                    <strong>
                      {item.product_batche.last_inventario_history ?
                      item.product_batche.last_inventario_history.user
                        ? `
                              ${item.product_batche.last_inventario_history.user.usuario_nome.substring(
                                0,
                                16
                              )}
                              ${
                                item.product_batche.last_inventario_history.user
                                  .usuario_nome.length >= 16
                                  ? "..."
                                  : ""
                              }
                            `
                        : "-":'-'}
                    </strong>
                    <strong>
                        {isRoot ?
                          (
                            <DeleteImport onClick={() => deleteWsmImport(
                              item?.product_batche?.last_inventario_history?.legacy,
                              item?.name
                            )}>
                              <Trash size={24} />
                            </DeleteImport>
                          ): <span />
                        }
                        {!isRoot && isAdmCD && !!userPlants.find(i => i.plant_name === item.name) ?
                        (
                            <DeleteImport onClick={() => deleteWsmImport(
                              item?.product_batche?.last_inventario_history?.legacy,
                              item?.name
                            )}>
                              <Trash size={24} />
                            </DeleteImport>
                          ): <span />
                      }
                    </strong>
                </Item>
              ))}
            </List>
          </>
        )}
        {loadingImportSap || loadingImportWms || loadingStatus ? (
          <Loading />
        ) : (
          <>
            {(!sapDowActive || !sapDupontActive) && (
              <ImportContainer>
                <h1>SAP</h1>
                <div>
                  {!sapDowActive && (
                    <Form ref={sapDowForm}>
                      <WrapForm>
                        <Row templatecolumns="1">
                          <Input
                            label={<Text id="import.dow" dm="DOW" />}
                            type="file"
                            name="import"
                            onChange={(event) =>
                              event.target.files[0] &&
                              handleSapDowImport(event.target.files)
                            }
                          />
                        </Row>
                      </WrapForm>
                    </Form>
                  )}
                  {!sapDupontActive && (
                    <Form ref={sapDupontForm}>
                      <WrapForm>
                        <Row templatecolumns="1">
                          <Input
                            label={<Text id="import.dupont" dm="DUPONT" />}
                            type="file"
                            name="import"
                            onChange={(event) =>
                              event.target.files[0] &&
                              handleSapDupontImport(event.target.files)
                            }
                          />
                        </Row>
                      </WrapForm>
                    </Form>
                  )}
                </div>
              </ImportContainer>
            )}
            {(!wmsDowActive ||
              !wmsDupontActive ||
              sapDowActive ||
              sapDupontActive) && (
              <ImportContainer>
                <h1>WMS</h1>
                <div>
                  {!wmsDowActive && sapDowActive && (
                    <Form ref={wmsDowForm}>
                      <WrapForm>
                        <Row templatecolumns="1">
                          <Input
                            label={<Text id="import.dow" dm="DOW" />}
                            type="file"
                            name="import"
                            onChange={(event) =>
                              event.target.files[0] &&
                              handleWmsDowImport(event.target.files)
                            }
                          />
                        </Row>
                      </WrapForm>
                    </Form>
                  )}
                  {!wmsDupontActive && sapDupontActive && (
                    <Form ref={wmsDupontForm}>
                      <WrapForm>
                        <Row templatecolumns="1">
                          <Input
                            label={<Text id="import.dupont" dm="DUPONT" />}
                            type="file"
                            name="import"
                            onChange={(event) =>
                              event.target.files[0] &&
                              handleWmsDupontImport(event.target.files)
                            }
                          />
                        </Row>
                      </WrapForm>
                    </Form>
                  )}
                </div>
              </ImportContainer>
            )}
            {fileData && fileData[0] && fileData.error && (
              <ImportContainer>
                <h1>{fileData[0].error}</h1>
              </ImportContainer>
            )}
          </>
        )}
      </Content>
    </Container>
  );
}

export default Responsible;
