import React, { useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResponsiblesActions from "~/store/ducks/responsible/list";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import Text from "~/components/Text";

import { Container, Filters, Title, Content, List, Item } from "../styles";
import { ButtonMini } from "~/styles/components";

function Responsibles() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.responsibles);

  const fectResponsibles = useCallback(() => {
    dispatch(ResponsiblesActions.request());
  }, [dispatch]);

  useEffect(() => {
    fectResponsibles();
  }, [fectResponsibles]);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="responsavel" dm="Responsável" />
          </small>
        </Title>
        <span>
          <ButtonMini
            btStyle="primary"
            onClick={() => history.push("/config/responsible/new")}>
            <Text id="novo.responsavel" dm="Novo Responsável" />
          </ButtonMini>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <List>
          <header>
            <p>
              <Text id="codigo" dm="Código" />
            </p>
            <p>
              <Text id="descricao" dm="Descrição" />
            </p>
          </header>
          {loading ? (
            <Loading />
          ) : (
            <>
              {data.data.map((item) => (
                <Link to={`/config/responsible/${item.id}`} key={item.id}>
                  <Item key={item.id}>
                    <p>{item.id}</p>
                    <p>{item.name}</p>
                  </Item>
                </Link>
              ))}
            </>
          )}
        </List>
      </Content>
    </Container>
  );
}

export default Responsibles;
