import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import UsersActions from "~/store/ducks/user/list";
import ResponsibleActions from "~/store/ducks/responsible/new";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { Settings } from "@styled-icons/ionicons-outline/Settings";

import Header from "~/components/Header";
import Loading from "~/components/Loading";
import { Input, SelectTest } from "~/components/Form";
import Text from "~/components/Text";

import {
  Container,
  Filters,
  Title,
  Content,
  SubTitle,
  Row,
  WrapBtns,
  WrapForm,
} from "../styles";
import { ButtonMini, Button } from "~/styles/components";

function Responsible() {
  const formLogin = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: users, loading} = useSelector(
    (state) => state.users
  );

  async function handleSubmit(values) {
    try {
      formLogin.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required("Nome de responsavel é obrigatório"),
        user: Yup.object().typeError("Selecione um usuário"),
      });
      await schema.validate(values, {
        abortEarly: false,
      });
      const data = {
        name: values.name,
        user_id: values.user.id,
      };
      await dispatch(ResponsibleActions.request(data));
      history.push("/config/responsible");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formLogin.current.setErrors(validationErrors);
      }
    }
  }

  useEffect(() => {
    dispatch(UsersActions.request());
  }, []);

  return (
    <Container>
      <Header />
      <Filters>
        <Title>
          <Settings size={24} />
          <Text id="config.title" dm="Configurações" />
          <small>
            <Text id="responsavel" dm="Responsável" />
          </small>
        </Title>
        <span>
          <ButtonMini btStyle="secondary" onClick={() => history.goBack()}>
            <LeftArrowAlt size={16} />
            <Text id="voltar" dm="Voltar" />
          </ButtonMini>
        </span>
      </Filters>
      <Content>
        <SubTitle>
          <Text id="informacoes" dm="Informações" />
        </SubTitle>
        {
          loading || !(users && users.data) ? (
            <Loading />
          ) : (
          <>
            <Form ref={formLogin} onSubmit={handleSubmit}>
              <WrapForm>
                <Row templatecolumns="1">
                  <Input
                    label={<Text id="nome" dm="Nome" />}
                    name="name"
                    placeholder="Digite o nome"
                  />
                </Row>
                <Row templatecolumns="1">
                  <SelectTest
                    label={<Text id="user" dm="Usuário" />}
                    name="user"
                    placeholder="Selecione usuário"
                    options={users && users.data}
                  />
                </Row>
              </WrapForm>
              <WrapBtns>
                <Button type="reset" btStyle="secondary">
                  <Text id="cancelar" dm="Cancelar" />
                </Button>
                <Button type="submit" btStyle="primary">
                  <Text id="cadastrar" dm="Cadastrar" />
                </Button>
              </WrapBtns>
            </Form>
          </>
          )
        }
      </Content>
    </Container>
  );
}

export default Responsible;
