import { call, put, select } from "redux-saga/effects";
import { notify } from "~/services/notification";
import api, { makeOptions } from "~/services/api";
import InventarioGroupActions from "../../ducks/inventarioGroup/new";

const getAuthData = (state) => state.auth.data;
const getLanguage = (state) => state.language;

export function* createInventarioGroup(action) {
  try {
    const { post } = action;
    const url = `inventario-group`;
    const authData = yield select(getAuthData);
    const { data } = yield call(api.post, url, post, makeOptions(authData));

    yield put(InventarioGroupActions.success(data));
    const {data: language} = yield select(getLanguage);
    notify("success", "Novo Grupo", "group.new", language);
  } catch (err) {
    //console.tron.log(err.response.data.error.message);
    notify("error", err.response.data.error.message);
    yield put(InventarioGroupActions.failure(err.response.data.error.message));
  }
}
