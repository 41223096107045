import styled from "styled-components";
import logo from "~/assets/images/logo-menu.png";

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px;
  background-color: ${({ theme }) => theme.colors.dark};
  span {
    display: flex;
    gap: 20px;
  }
`;

export const Logo = styled.img.attrs({
  src: logo,
})``;
