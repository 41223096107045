import React, { useRef, useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";
import { Container } from "./styles";

export default function Select({
  name,
  label,
  required,
  defaultValue: dfValue = undefined,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [value, setValue] = useState(defaultValue && defaultValue);

  useEffect(() => setValue(defaultValue ?? dfValue), [defaultValue]);

  function parseSelectValue(ref) {
    return ref.select.state.value;
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      padding: 8,
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontFamily: "Open Sans",
      fontSize: 14,
      color: "#A2258D",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1px #ddd solid`,
      padding: 8,
      fontFamily: "Open Sans",
      fontSize: 14,
      boxSizing: "border-box",
      outline: "none",
      backgroundColor: state.isSelected ? "#A2258D" : "transparent",
    }),
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      parseValue: parseSelectValue,
      clearValue: (selectRef) => {
        selectRef.select.clearValue();
      },
    });
  }, [selectRef.current, fieldName]);

  function handleChange(data) {
    setValue(data);
  }

  return (
    <Container>
      <label htmlFor={fieldName}>
        {label}
        {required && <span className="danger">*</span>}
      </label>
      <ReactSelect
        name={fieldName}
        styles={customStyles}
        value={value}
        ref={selectRef}
        classNamePrefix="react-select"
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
      {error && <span className="danger">{error}</span>}
    </Container>
  );
}
