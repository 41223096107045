import styled from "styled-components";
import { Input } from "~/components/Form";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  max-height: 1000px;
  overflow-y: auto;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.3);
  min-height: calc(100vh - 154px);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
  &.ativo {
    opacity: 1;
    visibility: visible;
  }
`;

export const WrapFilter = styled.div`
  width: 100%;
  max-width: 700px;
  transition: all ease 0.3s;
  transform: translateX(100%);
  visibility: hidden;
  &.ativo {
    transform: translateX(0);
    visibility: visible;
  }
  header {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    background: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
    p {
      font-family: ${({ theme }) => theme.fonts.titleBold};
      color: ${({ theme }) => theme.colors.blueDark};
      font-size: 14px;
    }
  }
  form {
    padding: 20px;
    background: #fff;
    min-height: calc(100vh - 204px);
  }
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(${(props) => props.templatecolumns}, 1fr);
  margin-bottom: 20px;
`;

export const WrapBtns = styled.div`
  position: absolute;
  bottom: 20px;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  button {
    margin-left: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;

  input {
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  }
`;
