import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.h6`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Logout = styled.button.attrs({
  type: "button",
})`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 16px;
  transition: 300ms ease;

  :hover {
    transform: scale(1.2);
  }
`;
