import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  svg {
    margin-right: 8px;
  }
`;

export const MonthDisplay = styled.div`
  font-size: 16px;
  padding: 10px 50px;
  background: #25a4cd;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-family: Gilroy-Bold;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-right: 16px;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonFilter = styled.button.attrs({
  type: "button",
})`
  color: ${({ theme }) => theme.colors.dark};
`;

export const Content = styled.section`
  width: 100%;
  padding: 60px 32px;
`;

export const ButtonModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9;

  transition: all ease 0.3s;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60px);
  svg {
    margin-right: 20px;
  }
  cursor: pointer;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  button {
    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.titleBold};
    color: ${({ theme }) => theme.colors.white};
    padding: 20px 30px;
    transition: all ease 0.3s;
    background: ${({ theme }) => theme.colors.lightDarkBlue};
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    :hover {
      transform: scale(0.95);
    }

    :first-child {
      background: ${({ theme }) => theme.colors.blueDark};
    }
  }
`;

export const List = styled.div``;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

export const ButtonAll = styled.button.attrs({
  type: "button",
})`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px ${({ theme }) => theme.colors.lightDarkBlue} solid;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightDarkBlue : "transparent"};
  transition: 300ms ease;
  margin-right: 12px;

  :hover {
    transform: scale(1.1);
  }

  :disabled {
    border: 2px #ddd solid;
    cursor: not-allowed;
  }
`;

export const Head = styled.header`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.5fr 3.5fr 0.5fr 1fr 1.5fr 1fr 1fr 0.5fr 1.5fr 3fr 2fr;
  padding: 4px 16px;
  p {
    color: ${({ theme }) => theme.colors.lightDarkBlue};
    font-family: ${({ theme }) => theme.fonts.titleBold};
    font-size: 11px;
    text-transform: uppercase;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const Radio = styled.input.attrs({
  type: "checkbox",
})`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 2px ${({ theme }) => theme.colors.lightDarkBlue} solid;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightDarkBlue : "transparent"};
  margin: 0 14px 0 2px;
  transition: all 300ms ease;
  appearance: none;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
  :disabled {
    border: 2px #ddd solid;
    cursor: not-allowed;
  }
  &:checked {
    background: ${({ theme }) => theme.colors.lightDarkBlue};
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 14.5fr 2fr;
  align-items: center;
  background-color: ${({ theme, reprovado }) =>
    reprovado ? theme.colors.magenta25 : theme.colors.white};
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  transition: 200ms ease;
  margin-bottom: 10px;

  :hover {
    transform: scale(1.015);
  }

  a {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 0.5fr 3.5fr 0.5fr 1fr 1.5fr 1fr 1fr 0.5fr 1.5fr 3fr;
    align-items: center;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
  }

  button {
    width: 110px;
    justify-content: center;
  }
`;

export const LabelAprov = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  p {
    flex: 1;
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  small {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    width: 100%;
    text-align: center;
    margin-top: 4px;
    color: #333;
    font-size: 12px;
    font-weight: bold;
  }
`;
