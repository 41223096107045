import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import locale_en from "~/lang/en.json";
import locale_pt from "~/lang/pt.json";
import locale_es from "~/lang/es.json";

import "react-toastify/dist/ReactToastify.css";

function notify(type, msg, id, language = "pt") {
  let message = "";

  if (id) {
    const data = {
      pt: locale_pt,
      en: locale_en,
      es: locale_es,
    };

    message = data[language][id];
  } else {
    message = msg;
  }

  if (type === "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toastsuccess",
      bodyClassName: "bodytoast",
    });
  }

  if (type === "info") {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return;
}

export { notify };
