// Dependencies
import styled from 'styled-components';

// StyledComponents
export const ButtonStyled = styled.button`
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  background: ${props => props.color};
  color: #fff;
  transition: 300ms ease;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    transform: scale(0.95);
  }
`;
