import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 800px;
  max-height: 550px;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
  position: relative;
  form textarea {
    height: 100px;
    resize: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #007eff;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #007eff;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgb(0 126 255 / 0.1);
  }
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(${(props) => props.templatecolumns}, 1fr);
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;

  input {
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
  }
`;

export const WrapBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  border-top: solid 1px ${({ theme }) => theme.colors.darkRgba8};
  padding-top: 20px;
  button {
    margin-left: 20px;
  }
`;

export const Title = styled.p`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 10px;
`;
export const ListNpi = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
`;
export const Item = styled.li`
  padding: 6px 16px;
  background: ${({ theme }) => theme.colors.darkRgba8};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.titleBold};
  color: ${({ theme }) => theme.colors.blueDark};
  border-radius: 4px;
  gap: 10px;
`;
export const BoxCLassif = styled.div`
  p {
    font-family: ${({ theme }) => theme.fonts.titleBold};
    font-size: 16px;
    color: #2b2b2b;
    margin-bottom: 8px;
  }
`;

export const BoxNpi = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 20px;
  border-bottom: solid 1px #f5f5f5;
  margin-bottom: 20px;
`;

export const BtnClose = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.dark};
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const FileList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;

  strong {
    margin-right: 16px;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & + li {
      margin-left: 16px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;

      img {
        height: 14px;
        margin-bottom: 8px;
      }
    }
  }
`;

export const AttachmentList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 4px 0;
  padding: 8px 0 8px 64px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;

    & + li {
      margin-top: 8px;
    }

    img {
      height: 14px;
      margin-right: 8px;
    }
  }
`;
