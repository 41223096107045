import { all, takeLatest } from "redux-saga/effects";

// auth
import { AuthTypes } from "../ducks/auth";
import { login } from "./auth";

// filter
import { FilterTypes } from "../ducks/filter";
import { addFilter } from "./filter";

//User
import { UsersTypes } from "../ducks/user/list";
import { fetchUsers } from "./user/list";
import { UserShowTypes } from "../ducks/user/show";
import { userShow } from "./user/show";

//Justificativa
import { JustificationTypes } from "../ducks/justification/new";
import { createJustification } from "./justification/new";
import { JustificationsTypes } from "../ducks/justification/list";
import { fetchJustifications } from "./justification/list";
import { JustificationShowTypes } from "../ducks/justification/show";
import { justificationShow } from "./justification/show";
import { JustificationEditTypes } from "../ducks/justification/edit";
import { justificationEdit } from "./justification/edit";
import { JustificationDeleteTypes } from "../ducks/justification/delete";
import { justificationDelete } from "./justification/delete";

//Responsible
import { ResponsibleTypes } from "../ducks/responsible/new";
import { createResponsible } from "./responsible/new";
import { ResponsiblesTypes } from "../ducks/responsible/list";
import { fetchResponsibles } from "./responsible/list";
import { ResponsibleShowTypes } from "../ducks/responsible/show";
import { responsibleShow } from "./responsible/show";
import { ResponsibleEditTypes } from "../ducks/responsible/edit";
import { responsibleEdit } from "./responsible/edit";
import { ResponsibleDeleteTypes } from "../ducks/responsible/delete";
import { responsibleDelete } from "./responsible/delete";

//Product Batch
import { ProductBatchTypes } from "../ducks/productBatch/new";
import { createProductBatch } from "./productBatch/new";
import { ProductBatchesTypes } from "../ducks/productBatch/list";
import { fetchProductBatches } from "./productBatch/list";
import { ProductBatchShowTypes } from "../ducks/productBatch/show";
import { productBatchShow } from "./productBatch/show";
import { ProductBatchEditTypes } from "../ducks/productBatch/edit";
import { productBatchEdit } from "./productBatch/edit";
import { ProductBatchDeleteTypes } from "../ducks/productBatch/delete";
import { productBatchDelete } from "./productBatch/delete";

//Inventario Group
import { InventarioGroupTypes } from "../ducks/inventarioGroup/new";
import { createInventarioGroup } from "./inventarioGroup/new";
import { InventarioGroupsTypes } from "../ducks/inventarioGroup/list";
import { fetchInventarioGroups } from "./inventarioGroup/list";
import { InventarioGroupShowTypes } from "../ducks/inventarioGroup/show";
import { inventarioGroupShow } from "./inventarioGroup/show";
import { InventarioGroupEditTypes } from "../ducks/inventarioGroup/edit";
import { inventarioGroupEdit } from "./inventarioGroup/edit";
import { InventarioGroupDeleteTypes } from "../ducks/inventarioGroup/delete";
import { inventarioGroupDelete } from "./inventarioGroup/delete";

//Inventario Group User
import { InventarioGroupUsersTypes } from "../ducks/inventarioGroupUser/list";
import { fetchInventarioGroupUsers } from "./inventarioGroupUser/list";
import { InventarioGroupUserTypes } from "../ducks/inventarioGroupUser/new";
import { createInventarioGroupUser } from "./inventarioGroupUser/new";
import { InventarioGroupUserDeleteTypes } from "../ducks/inventarioGroupUser/delete";
import { inventarioGroupUserDelete } from "./inventarioGroupUser/delete";

//Inventory History
import { InventarioHistoryDeleteTypes } from "../ducks/inventoryHistory/delete";
import { inventarioHistoryDelete } from "./inventoryHistory/delete";

//Product Batch Alert
import { ProductBatchAlertsTypes } from "../ducks/productBatchAlert/list";
import { fetchProductBatchAlerts } from "./productBatchAlert/list";
import { ProductBatchAlertShowTypes } from "../ducks/productBatchAlert/show";
import { productBatchAlertShow } from "./productBatchAlert/show";
import { ProductBatchAlertEditTypes } from "../ducks/productBatchAlert/edit";
import { productBatchAlertEdit } from "./productBatchAlert/edit";
import { ProductBatchIndicatorsTypes } from "../ducks/productBatchAlert/indicators";
import { productBatchIndicators } from "./productBatchAlert/indicators";
import { ProductBatchAlertExportTypes } from "../ducks/productBatchAlert/export";
import { productBatchAlertExport } from "./productBatchAlert/export";

//Import Sap
import { SapImportTypes } from "../ducks/importSap/new";
import { createSapImport, deleteSapImport } from "./importSap/new";
import { SapImportsTypes } from "../ducks/importSap/list";
import { fetchSapImports } from "./importSap/list";

//Import
import { WmsImportTypes } from "../ducks/importWms/new";
import { createWmsImport, deleteWsmImport } from "./importWms/new";
import { WmsImportsTypes } from "../ducks/importWms/list";
import { fetchWmsImports } from "./importWms/list";

//Justify Alert
import { JustifyAlertTypes } from "../ducks/justifyAlert/new";
import { createJustifyAlert } from "./justifyAlert/new";

//Justify File
import { JustifyFileTypes } from "../ducks/justifyFile/new";
import { createJustifyFile } from "./justifyFile/new";

//First Aproval
import { FirstAprovalTypes } from "../ducks/firstAproval/new";
import { createFirstAproval } from "./firstAproval/new";
import { FirstAprovalFastTypes } from "../ducks/firstAproval/fast";
import { createFirstAprovalFast } from "./firstAproval/fast";

//Corteva Responsible
import { CortevaResponsibleTypes } from "../ducks/cortevaResponsible/new";
import { createCortevaResponsible } from "./cortevaResponsible/new";

//Second Aproval
import { SecondAprovalEditTypes } from "../ducks/secondAproval/edit";
import { secondAprovalEdit } from "./secondAproval/edit";

//Language
import { LanguageTypes } from "../ducks/language";
import { fetchLanguage } from "./language";

// JustificationFileGetLink
import { JustificationFileGetLinkTypes } from "../ducks/justifyGetLink/get";
import { fetchJustificationFileGetLink } from "./justifyGetLink/get";

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest(AuthTypes.REQUEST, login),

    // filter
    takeLatest(FilterTypes.REQUEST, addFilter),

    // User
    takeLatest(UsersTypes.REQUEST, fetchUsers),
    takeLatest(UserShowTypes.REQUEST, userShow),

    //Justification
    takeLatest(JustificationTypes.REQUEST, createJustification),
    takeLatest(JustificationsTypes.REQUEST, fetchJustifications),
    takeLatest(JustificationShowTypes.REQUEST, justificationShow),
    takeLatest(JustificationEditTypes.REQUEST, justificationEdit),
    takeLatest(JustificationDeleteTypes.REQUEST, justificationDelete),

    //Responsible
    takeLatest(ResponsibleTypes.REQUEST, createResponsible),
    takeLatest(ResponsiblesTypes.REQUEST, fetchResponsibles),
    takeLatest(ResponsibleShowTypes.REQUEST, responsibleShow),
    takeLatest(ResponsibleEditTypes.REQUEST, responsibleEdit),
    takeLatest(ResponsibleDeleteTypes.REQUEST, responsibleDelete),

    //Product Batch
    takeLatest(ProductBatchTypes.REQUEST, createProductBatch),
    takeLatest(ProductBatchesTypes.REQUEST, fetchProductBatches),
    takeLatest(ProductBatchShowTypes.REQUEST, productBatchShow),
    takeLatest(ProductBatchEditTypes.REQUEST, productBatchEdit),
    takeLatest(ProductBatchDeleteTypes.REQUEST, productBatchDelete),

    //Inventario Group
    takeLatest(InventarioGroupTypes.REQUEST, createInventarioGroup),
    takeLatest(InventarioGroupsTypes.REQUEST, fetchInventarioGroups),
    takeLatest(InventarioGroupShowTypes.REQUEST, inventarioGroupShow),
    takeLatest(InventarioGroupEditTypes.REQUEST, inventarioGroupEdit),
    takeLatest(InventarioGroupDeleteTypes.REQUEST, inventarioGroupDelete),

    //Inventario Group User
    takeLatest(InventarioGroupUsersTypes.REQUEST, fetchInventarioGroupUsers),
    takeLatest(InventarioGroupUserTypes.REQUEST, createInventarioGroupUser),
    takeLatest(
      InventarioGroupUserDeleteTypes.REQUEST,
      inventarioGroupUserDelete
    ),

    takeLatest(InventarioHistoryDeleteTypes.REQUEST, inventarioHistoryDelete),

    //Product Batch Alert
    takeLatest(ProductBatchAlertsTypes.REQUEST, fetchProductBatchAlerts),
    takeLatest(ProductBatchAlertShowTypes.REQUEST, productBatchAlertShow),
    takeLatest(ProductBatchAlertEditTypes.REQUEST, productBatchAlertEdit),
    takeLatest(ProductBatchIndicatorsTypes.REQUEST, productBatchIndicators),
    takeLatest(ProductBatchAlertExportTypes.REQUEST, productBatchAlertExport),

    //Import Sap
    takeLatest(SapImportTypes.REQUEST, createSapImport),
    takeLatest(SapImportTypes.DELETE, deleteSapImport),
    takeLatest(SapImportsTypes.REQUEST, fetchSapImports),

    //Import Wms
    takeLatest(WmsImportTypes.REQUEST, createWmsImport),
    takeLatest(WmsImportsTypes.REQUEST, fetchWmsImports),
    takeLatest(WmsImportTypes.DELETE, deleteWsmImport),

    //Justify Alert
    takeLatest(JustifyAlertTypes.REQUEST, createJustifyAlert),

    //Justify File
    takeLatest(JustifyFileTypes.REQUEST, createJustifyFile),

    //First Aproval
    takeLatest(FirstAprovalTypes.REQUEST, createFirstAproval),
    takeLatest(FirstAprovalFastTypes.REQUEST, createFirstAprovalFast),

    //Corteva Responsible
    takeLatest(CortevaResponsibleTypes.REQUEST, createCortevaResponsible),

    //Second Aproval
    takeLatest(SecondAprovalEditTypes.REQUEST, secondAprovalEdit),

    //Language
    takeLatest(LanguageTypes.REQUEST, fetchLanguage),

    // JustificationFileGetLink
    takeLatest(JustificationFileGetLinkTypes.REQUEST, fetchJustificationFileGetLink),
  ]);
}
