import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a,
  span {
    font-family: "Gilroy-Bold";
    font-size: 14px;
    margin-right: 24px;
    transition: 300ms ease;
    color: ${({ theme }) => theme.colors.white};
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    position: relative;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    :hover {
      opacity: 1;
      div {
        opacity: 1;
        visibility: visible;
      }
    }
    &.active {
      opacity: 1;
    }
    &.submenu {
      cursor: initial;
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

export const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  padding: 35px 40px;
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  column-gap: 30px;
  text-align: left;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  z-index: 9;

  a {
    color: ${({ theme }) => theme.colors.dark};
    height: auto;
    /* &:nth-child(-n + 2) {
      font-size: 16px;
    } */
  }
`;
