import styled from "styled-components";

export const ImportContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }
`;

export const StatusBar = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & + li {
    margin-left: 120px;
  }

  & strong {
    background: ${(props) => (props.active ? "#3BBCA3" : "#fff")};
    padding: 10px 15px;
    border-radius: 4px;
    display: inline-block;
    color: ${(props) => (props.active ? "#fff" : "#333")};
  }

  & small {
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const List = styled.div`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ddd;

  header {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 6fr 2fr 2fr 2fr 1fr;
    padding: 8px 16px 16px 16px;
    p {
      color: ${({ theme }) => theme.colors.lightDarkBlue};
      font-family: ${({ theme }) => theme.fonts.titleBold};
      font-size: 11px;
      text-transform: uppercase;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 6fr 2fr 2fr 2fr 1fr;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  p {
    font-family: ${({ theme }) => theme.fonts.titleSemiBold};
    font-size: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
