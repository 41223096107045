import styled from "styled-components";
import logo from "~/assets/images/logo.png";
import bgLogin from "~/assets/images/bg-login.png";
export { ActivityIndicator } from "~/styles/components";

export const Container = styled.main`
  display: flex;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;

  padding: 48px;
  background: ${({ theme }) => theme.colors.dark};

  input.first {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input.last {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const ImageBackground = styled.figure`
  flex: 1;
  height: 100%;
  background: #ccc url(${bgLogin}) no-repeat center center;
`;

export const Logo = styled.img.attrs({
  src: logo,
})``;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
`;
export const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  margin-bottom: 24px;
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.white};
`;

export const Ghost = styled.div``;
