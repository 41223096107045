import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { PackageIcon, FilterAlt } from "@styled-icons/boxicons-regular";
import {
  CheckmarkCircleOutline,
  AlertCircleOutline,
} from "@styled-icons/evaicons-outline/";
import Paginator from "~/components/Paginator";
import ProductBatchAlertsActions from "~/store/ducks/productBatchAlert/list";
import ProductBatchAlertExportActions from "~/store/ducks/productBatchAlert/export";
import InventarioGroupUsersActions from "~/store/ducks/inventarioGroupUser/list";
import JustifyFileActions from "~/store/ducks/justifyFile/new";
import ProductBatchAlertActions from "~/store/ducks/productBatchAlert/indicators";

import {
  Container,
  Filters,
  Title,
  WrapperButtons,
  ButtonFilter,
  Content,
  List,
  ListHeader,
  ButtonAll,
  Head,
  ItemContainer,
  Radio,
  Item,
  LabelAprov,
  Action,
  MonthDisplay,
} from "./styles";

import { ButtonMini } from "~/styles/components";
import Header from "~/components/Header";
import Loading from "~/components/Loading";
import Button from "~/components/Button";
import {
  Filter,
  ModalJustifyAlert,
  ModalAproval,
  ModalSap,
  ModalSecondAproval,
  ModalFastAproval,
  ModalDelete,
} from "~/components/Pages/Inventory/";
import Text from "~/components/Text";

function Inventory() {
  const [openDelete, setOpenDelete] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: productBatchAlerts, loading } = useSelector(
    (state) => state.productBatchAlerts
  );
  const [currentPage, setCurrentPage] = useState(
    (productBatchAlerts &&
      productBatchAlerts.pagination &&
      productBatchAlerts.pagination.page) ||
      1
  );
  const { data: groups } = useSelector((state) => state.inventarioGroupUsers);
  const { data: user } = useSelector((state) => state.userShow);
  const { data: filters } = useSelector((state) => state.filter);
  const { data: exportData, loading: exportLoading } = useSelector(
    (state) => state.productBatchAlertExport
  );

  const { loading: inventoryHistoryLoading } = useSelector(
    (state) => state.inventoryHistoryDelete
  );

  const [list, setList] = useState([]);
  const [listAcceptable, setListAcceptable] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAproval, setOpenAproval] = useState(false);
  const [openSecondAproval, setOpenSecondAproval] = useState(false);
  const [openSap, setOpenSap] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [modalAprovalData, setModalAprovalData] = useState(null);
  const [modalSecondAprovalData, setModalSecondAprovalData] = useState(null);
  const [openFastAproval, setOpenFastAproval] = useState(false);
  const [modalSapData, setModalSapData] = useState(null);
  const [exportActive, setExportActive] = useState(false);

  const [armazemStatus, setArmazemStatus] = useState(false);
  const [fourPlStatus, setFourPlStatus] = useState(false);
  const [cortevaStatus, setCortevaStatus] = useState(false);

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const currentMonth = monthNames[new Date().getMonth()];

  const fetchIndicators = useCallback(() => {
    dispatch(ProductBatchAlertActions.request());
  }, [dispatch]);

  function onFilter(data) {
    setFilterActive(false);
    const filter = {
      paginate: 1,
      ...data,
    };

    dispatch(ProductBatchAlertsActions.request(filter));
    dispatch(
      InventarioGroupUsersActions.request({
        user_id: user && user.usuario_id,
      })
    );
    dispatch(JustifyFileActions.reset());
  }

  const handleClose = () => {
    setOpen(false);
    setOpenAproval(false);
    setOpenSap(false);
    setOpenSecondAproval(false);
    setOpenFastAproval(false);
  };

  const toggleModalDelete = () => {
    setOpenDelete((old) => !old);
  };

  const fectSegments = useCallback(
    (page) => {
      let filter = {
        paginate: page,
      };

      if (filters) {
        filter = {
          paginate: page,
          ...filters.endPoint,
        };
      }

      dispatch(ProductBatchAlertsActions.request(filter));
      dispatch(
        InventarioGroupUsersActions.request({
          user_id: user && user.usuario_id,
        })
      );
      dispatch(JustifyFileActions.reset());
      window.scroll({
        top: 0,
      });
    },
    [dispatch, filters]
  );

  const onPressDelete = () => {
    toggleModalDelete();
  };

  const handleStatus = (status) => {
    switch (status) {
      case "reprovado":
        return "Reprovado";
      case "finalizado":
        return "Finalizado";
      case "aprovado":
        return "Aprovado pela 4PL";
      case "aprovadocorteva":
        return "Aprovado pela Corteva";
      case "pendenteAprovacao":
        return "Pendente de aprovação";
      case "pendenteJustificativa":
        return "Pendente de justificativa";
      default:
        return "-";
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    setExportActive(false);
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getFileData = async () => {
    let filter = {};

    if (filters) {
      filter = {
        ...filters.endPoint,
      };
    }

    dispatch(ProductBatchAlertExportActions.request(filter));
    setExportActive(true);
  };

  const generateFile = () => {
    if (exportData && exportData.data) {
      const newFile = [];
      const fileName = `INVENTARIO`;

      exportData.data.forEach((line) => {
        const {
          product_batch,
          inventario_history,
          inventario_history_sap,
          justify_alert,
          status,
        } = line;

        const PLANTA = product_batch.plant.name;
        const CODIGO = product_batch.product.code;
        const DESCRICAO =
          product_batch.product.description || product_batch.product.name;
        const LOTE = product_batch.batch;
        const SAP = inventario_history_sap.general_quantity;
        const WMS = inventario_history.general_quantity;
        const DIFERENCA =
          inventario_history_sap.general_quantity -
          inventario_history.general_quantity;
        const JUSTIFICATIVA = justify_alert ? justify_alert.justify.name : "-";
        const OBSERVACAO = justify_alert ? justify_alert.description : "-";
        const STATUS = handleStatus(status);

        const fileLine = {
          PLANTA,
          CODIGO,
          DESCRICAO,
          LOTE,
          SAP,
          WMS,
          DIFERENCA,
          JUSTIFICATIVA,
          OBSERVACAO,
          STATUS,
        };

        newFile.push(fileLine);
      });

      exportToCSV(newFile, fileName);
    }
  };

  const addToListChecked = async (checked, item) => {
    if (checked) {
      await setListChecked([...listChecked, item]);
    } else {
      let newListChecked = listChecked.filter((i) => i.id !== item.id);
      await setListChecked(newListChecked);
    }
  };

  useEffect(() => {
    if (!inventoryHistoryLoading) {
      fectSegments(currentPage);
    }
  }, [fectSegments, currentPage, filters, inventoryHistoryLoading]);

  useEffect(() => {
    if (productBatchAlerts) {
      setList(productBatchAlerts.data);

      const newListAcceptable = new Array();
      productBatchAlerts.data.filter((item) => {
        if (
          item.justify_name &&
          item.status === "pendenteAprovacao" &&
          (item.justify_name === "Pendente Entrada" ||
            item.justify_name === "Pendente Saída" ||
            item.justify_name === "Fator de Medida")
        ) {
          newListAcceptable.push(item);
        }
      });
      setListAcceptable(newListAcceptable);
    }

    if (groups && groups.data[0]) {
      groups.data.forEach((item) => {
        const name = item.inventario_group && item.inventario_group.name;
        if (name && name === "Armazém") {
          setArmazemStatus(true);
        } else if (name && name === "4PL") {
          setFourPlStatus(true);
        } else if (name && name === "Corteva") {
          setCortevaStatus(true);
        }
      });
    }
  }, [productBatchAlerts, groups]);

  useEffect(() => {
    if (!inventoryHistoryLoading) {
      fetchIndicators();
    }
  }, [fetchIndicators, inventoryHistoryLoading]);

  useEffect(() => {
    if (!exportLoading && exportActive) {
      generateFile();
    }
  }, [exportLoading]);

  return (
    <Container>
      <Header />
      <ModalJustifyAlert
        open={open}
        product_batch_alert_id={modalId}
        handleClose={handleClose}
        onJustifySubmit={() => {
          handleClose();
          fectSegments(currentPage);
        }}
      />
      <ModalDelete
        open={openDelete}
        handleClose={toggleModalDelete}
        handleDelete={onPressDelete}
      />

      <ModalAproval
        open={openAproval}
        justify_alert={modalAprovalData}
        handleClose={handleClose}
        onAprovalSubmit={() => {
          handleClose();
          fectSegments(currentPage);
        }}
      />
      <ModalSecondAproval
        open={openSecondAproval}
        second_approval={modalSecondAprovalData}
        justify_alert={modalAprovalData}
        handleClose={handleClose}
        onAprovalSubmit={() => {
          handleClose();
          fectSegments(currentPage);
        }}
      />
      <ModalSap
        open={openSap}
        handleClose={handleClose}
        productBatch={modalSapData}
        product_batch_alert_id={modalId}
        onSapSubmit={() => {
          handleClose();
          fectSegments(currentPage);
        }}
      />
      <ModalFastAproval
        open={openFastAproval}
        list={listChecked}
        handleClose={handleClose}
        onFastAprovalSubmit={() => {
          handleClose();
          fectSegments(currentPage);
          setListChecked([]);
        }}
      />
      <Filters>
        <Title>
          <PackageIcon size={24} />
          <Text id="rec.invetario" dm="Reconciliação do Inventário" />
        </Title>
        <div>
          <MonthDisplay>{currentMonth}</MonthDisplay>
        </div>
        <WrapperButtons>
          <Button
            color="#1976D2"
            loading={exportLoading || exportActive}
            onClick={() => getFileData()}>
            <Text id="menu.export" dm="Exportar CSV" />
          </Button>
          <ButtonMini
            btStyle="primary"
            onClick={() => history.push("/config/import")}>
            <Text id="menu.import" dm="Importar" />
          </ButtonMini>
          <Button
            color={listChecked && listChecked[0] ? "#1976D2" : "#ddd"}
            disabled={!(listChecked && listChecked[0])}
            onClick={() => setOpenFastAproval(true)}>
            <Text id="aprovacao" dm="Aprovação" />
          </Button>

          <ButtonFilter onClick={() => setFilterActive(!filterActive)}>
            <FilterAlt size={24} color={filterActive ? "#1976D2" : ""} />
          </ButtonFilter>
        </WrapperButtons>
      </Filters>
      <Filter
        active={filterActive}
        onClose={(e) => setFilterActive(e)}
        onFilter={onFilter}
        onPageChange={setCurrentPage}
      />
      <Content>
        {loading ? (
          <Loading />
        ) : (
          <>
            {list && list[0] ? (
              <>
                <List>
                  <ListHeader>
                    <ButtonAll
                      onClick={() => setListChecked(listAcceptable)}
                      active={
                        listAcceptable.length === listChecked.length &&
                        listAcceptable.length > 0
                      }
                      disabled={listAcceptable.length <= 0}
                    />
                    <Head>
                      <p>ID</p>
                      <p>
                        <Text id="produtoDescricao" dm="Descrição" />
                      </p>
                      <p>
                        <Text id="unity" dm="Unidade" />
                      </p>
                      <p>
                        <Text id="codigo" dm="Código" />
                      </p>
                      <p>
                        <Text id="inventory.batch" dm="Lote" />
                      </p>
                      <p>
                        <Text id="inventory.plant" dm="Planta" />
                      </p>
                      <p>
                        <Text id="quantidadeSap" dm="Quantidade SAP" />
                      </p>
                      <p>
                        <Text id="quantidadeWms" dm="Quantidade WMS" />
                      </p>
                      <p>
                        <Text id="divergencia" dm="Divergência" />
                      </p>
                      <p>
                        <Text id="justificativa" dm="Justificativa" />
                      </p>
                      <p>
                        <Text id="acao" dm="Ação" />
                      </p>
                    </Head>
                  </ListHeader>
                  {list &&
                    list[0] &&
                    list.map((item) => (
                      <ItemContainer key={item.id}>
                        <Radio
                          onClick={(e) =>
                            addToListChecked(e.target.checked, item)
                          }
                          checked={listChecked.find((i) => i.id === item.id)}
                          defaultChecked={listChecked.find(
                            (i) => i.id === item.id
                          )}
                          disabled={
                            !listAcceptable.find((i) => i.id === item.id)
                          }
                        />

                        <Item reprovado={item.status === "reprovado"}>
                          <Link to={`/inventory/${item.id}`}>
                            <p>{item.id}</p>
                            <p>
                              {item.product_batch
                                ? item.product_batch.product.name
                                : "-"}
                            </p>
                            <p>
                              {item.product_batch &&
                              item.product_batch.product.unit
                                ? item.product_batch.product.unit.name
                                : "-"}
                            </p>
                            <p>
                              {item.product_batch
                                ? item.product_batch.product.code
                                : "-"}
                            </p>
                            <p>
                              {item.product_batch
                                ? item.product_batch.batch
                                : "-"}
                            </p>
                            <p>
                              {item.product_batch
                                ? item.product_batch.plant.name
                                : "-"}
                            </p>
                            <p>
                              {item.inventario_history_sap
                                ? item.inventario_history_sap.general_quantity
                                : "-"}
                            </p>
                            <p>
                              {item.inventario_history
                                ? item.inventario_history.general_quantity
                                : "-"}
                            </p>
                            <p>
                              {(
                                item.inventario_history_sap.general_quantity -
                                item.inventario_history.general_quantity
                              ).toLocaleString() || "-"}
                            </p>
                            <p>
                              {(item.status !== "rejeitado" &&
                                item.justify_name) ||
                                "-"}
                            </p>
                          </Link>

                          {item.status === "finalizado" && (
                            <LabelAprov>
                              <CheckmarkCircleOutline
                                size="25"
                                color="#45c4c4"
                              />
                              <p>Finalizado</p>
                            </LabelAprov>
                          )}
                          {item.status === "aprovadocorteva" && fourPlStatus && (
                            <button
                              type="button"
                              onClick={() => {
                                setModalSapData(item.product_batch);
                                setModalId(item.id);
                                setOpenSap(true);
                              }}>
                              <LabelAprov>
                                <CheckmarkCircleOutline
                                  size="25"
                                  color="#45c4c4"
                                  title="Aprovado por:"
                                />
                                <p>{item.responsible_description}</p>
                              </LabelAprov>
                            </button>
                          )}
                          {item.status === "aprovado" && cortevaStatus && (
                            <button
                              type="button"
                              onClick={() => {
                                setModalSecondAprovalData(
                                  item.justify_alert.first_aproval
                                    .second_aproval
                                );
                                setModalAprovalData(item.justify_alert);
                                setOpenSecondAproval(true);
                              }}>
                              <LabelAprov>
                                <AlertCircleOutline
                                  size="25"
                                  color="#e7a732"
                                  title="Pendente para aprovação:"
                                />{" "}
                                <p>{item.responsible_description}</p>
                              </LabelAprov>
                            </button>
                          )}
                          {item.status === "pendenteAprovacao" && fourPlStatus && (
                            <Action>
                              <ButtonMini
                                btStyle="aproved"
                                onClick={() => {
                                  setModalAprovalData(item.justify_alert);
                                  setOpenAproval(true);
                                }}>
                                <Text id="aprovar" dm="Aprovar" />
                              </ButtonMini>
                              <small>
                                {item.created_at &&
                                  format(
                                    new Date(item.created_at),
                                    "dd/MM/yyyy HH:mm"
                                  )}
                              </small>
                            </Action>
                          )}
                          {(item.status === "pendenteJustificativa" ||
                            item.status === "reprovado") &&
                            armazemStatus && (
                              <Action>
                                <ButtonMini
                                  btStyle="primary"
                                  onClick={() => {
                                    setModalId(item.id);
                                    setOpen(true);
                                  }}>
                                  <Text id="justificativa" dm="Justificativa" />
                                </ButtonMini>
                                <small>
                                  {item.created_at &&
                                    format(
                                      new Date(item.created_at),
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                </small>
                              </Action>
                            )}
                        </Item>
                      </ItemContainer>
                    ))}
                </List>
                <Paginator
                  pagination={
                    productBatchAlerts && productBatchAlerts.pagination
                  }
                  onPageChange={setCurrentPage}
                />
              </>
            ) : (
              <p>Sem divergências para o filtro solicitado</p>
            )}
          </>
        )}
      </Content>
    </Container>
  );
}

export default Inventory;
