import styled, { css } from "styled-components";
import { SpinnerIos } from "@styled-icons/fluentui-system-regular";

export const PageContainer = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Button = styled.button`
  display: flex;
  padding: 16px 32px;
  border-radius: 6px;
  font-size: 16px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) =>
    btStyle === "primary" &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `};
  ${({ btStyle }) =>
    btStyle === "delete" &&
    css`
      background-color: ${({ theme }) => theme.colors.danger};
      color: ${({ theme }) => theme.colors.white};
    `};
  ${({ btStyle }) =>
    btStyle === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.colors.darkRgba15};
      color: ${({ theme }) => theme.colors.lightDarkBlue};
    `};
`;

export const ButtonMini = styled.button`
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) =>
    btStyle === "aprov" &&
    css`
      background-color: #ffbf47;
      color: ${({ theme }) => theme.colors.white};
    `};

  ${({ btStyle }) =>
    btStyle === "primary" &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `};

  ${({ btStyle }) =>
    btStyle === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.colors.darkRgba15};
      color: ${({ theme }) => theme.colors.dark};
    `};

  ${({ btStyle }) =>
    btStyle === "aproved" &&
    css`
      background-color: #3bbca3;
      color: ${({ theme }) => theme.colors.white};
    `};
`;

export const Badge = styled.div`
  font-family: ${({ theme }) => theme.fonts.titleBold};
  display: flex;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  span {
    color: ${({ theme }) => theme.colors.lightDarkBlue};
  }

  ${({ bgColor }) =>
    bgColor === "primary" &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `};

  ${({ bgColor }) =>
    bgColor === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.colors.darkRgba15};
      color: ${({ theme }) => theme.colors.dark};
    `};
`;

export const ActivityIndicator = styled(SpinnerIos).attrs((props) => ({
  size: props.size || 24,
  color: props.color || "currentColor",
}))`
  animation: iconSpin 2s infinite linear;
`;
